import * as requestFromServer from "./settingCrud";
import {settingSlice, callTypes} from "./settingSlice";

const {actions} = settingSlice;

export const fetchSetting = (admin) => dispatch => {
  if (!admin) {
    return dispatch(actions.settingFetched({ settingForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findSetting(admin)
    .then(response => {
      const setting = response.data;
      dispatch(actions.settingFetched({ settingForEdit: setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't find setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.settingFetched({ settingForEdit: undefined }));
    });
};

export const createSetting = settingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSetting(settingForCreation)
    .then(response => {
      const { setting } = response.data;
      dispatch(actions.settingCreated(setting ));
    })
    .catch(error => {
      error.clientMessage = "Can't create setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSetting = setting => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSetting(setting)
    .then(() => {
      dispatch(actions.settingUpdated(setting ));
    })
    .catch(error => {
      error.clientMessage = "Can't update setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

///

export const fetchMailgunConfig = (admin) => dispatch => {
  if (!admin) {
    return dispatch(actions.mailgunConfigFetched({ mailgunconfigForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findMailgunConfig(admin)
    .then(response => {
      const setting = response.data;
      dispatch(actions.mailgunConfigFetched({ mailgunconfigForEdit: setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't find setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.mailgunConfigFetched({ mailgunconfigForEdit: undefined }));
    });
};

export const createMailgunConfig = settingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createMailgunConfig(settingForCreation)
    .then(response => {
      const { setting } = response.data;
      dispatch(actions.mailgunConfigCreated(setting ));
    })
    .catch(error => {
      error.clientMessage = "Can't create setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateMailgunConfig = setting => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateMailgunConfig(setting)
    .then(() => {
      dispatch(actions.mailgunConfigUpdated(setting ));
    })
    .catch(error => {
      error.clientMessage = "Can't update setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};