/* eslint-disable no-self-assign */
import { createSlice } from "@reduxjs/toolkit";

const initialRedeemsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  redeemForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const redeemsSlice = createSlice({
  name: "redeems",
  initialState: initialRedeemsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getRedeemById
    redeemFetched: (state, action) => {
      state.actionsLoading = false;
      state.redeemForEdit = action.payload.redeemForEdit;
      state.error = null;
    },
    // getRedeemById
    redeemFraudData: (state, action) => {
      state.actionsLoading = false;
      state.redeemFraudData = action.payload.redeemFraudData;
      state.error = null;
    },
    // getRedeemById
    phoneVerificationDone: (state, action) => {
      state.actionsLoading = false;
      state.phoneVerificationDone = true;
      state.error = null;
    },
    //
    paymentSent: (state, action) => {
      state.actionsLoading = false;
      state.paymentSent = action.payload.result;
      state.error = null;
    },
    // getRedeemDetailsById
    redeemDetailsFetched: (state, action) => {
      state.actionsLoading = false;
      state.redeemDetails = action.payload.redeemDetails;
      state.error = null;
    },
    // findRedeems
    redeemsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createRedeem
    redeemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.redeem);
    },
    // updateRedeem
    redeemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.payload.redeem._id) {
          return action.payload.redeem;
        }
        return entity;
      });
    },
    // deleteRedeem
    redeemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities;
    },
    // deleteRedeems
    redeemsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // redeemsUpdateState
    redeemsStatusUpdated: (state, action) => {
      console.log(action);
      state.actionsLoading = false;
      state.error = null;
      state.redeemStatusUpdate = true;
      const { ids, status } = action.payload;
      if (state.entities)
        state.entities = state.entities.map((entity) => {
          if (ids.findIndex((id) => id === entity._id) > -1) {
            entity.status = status;
          }
          return entity;
        });
    },
  },
});
