import axios from "axios";

export const CUSTOMERS_URL = "api/offers";

axios.defaults.headers.post["Content-Type"] = "application/json";


// CREATE =>  POST: add a new offer to the server
export function createOffer(offer) {
  return axios.post(CUSTOMERS_URL, { offer });
}

// READ
export function getAllOffers() {
  return axios.get(CUSTOMERS_URL);
}

export function getOfferById(offerId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/offer/${offerId}`);
}

export function getOfferDetailsById(offerId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/users/details/${offerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOffers(queryParams) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/offer?group=false`, JSON.stringify(queryParams));
}

// UPDATE => PUT: update the offer on the server
export function updateOffer(offer) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/offer/sendReward/${offer._id}`, { offer });
}

// UPDATE Status
export function updateStatusForOffers(ids, type) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/offer/state/ids`, {
    ids,
    type
  });
}

// DELETE => delete the offer from the server
export function deleteOffer(offerId) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/offer/${offerId}`);
}

// DELETE Offers by ids
export function deleteOffers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteOffers`, { ids });
}
