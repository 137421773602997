import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../_redux/rewards/rewardsActions";
import { RewardEditDialogHeader } from "./RewardEditDialogHeader";
import { RewardEditForm } from "./RewardEditForm";
import { useRewardsUIContext } from "../RewardsUIContext";


export function RewardEditDialog() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return {
      id: rewardsUIContext.selectedId,
      setIds: rewardsUIContext.setIds,
      countryId: rewardsUIContext.countryId,
      queryParams: rewardsUIContext.queryParams,
      showEditRewardDialog: rewardsUIContext.showEditRewardDialog,
      closeEditRewardDialog: rewardsUIContext.closeEditRewardDialog,
      initReward: rewardsUIContext.initReward,
    };
  }, [rewardsUIContext]);

  // Rewards Redux state
  const dispatch = useDispatch();
  const { actionsLoading, rewardForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.rewards.actionsLoading,
      rewardForEdit: state.rewards.rewardForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting reward by seleted id
    dispatch(actions.fetchReward(rewardsUIProps.id));
  }, [rewardsUIProps.id, dispatch]);

  const saveReward = (reward) => {
    if (!rewardsUIProps.id) {
      // server request for creating rewards
      dispatch(actions.createReward(reward)).then(() => {
        // refresh list after deletion
        dispatch(
          actions.fetchRewards(
            rewardsUIProps.queryParams,
            rewardsUIProps.countryId
          )
        ).then(() => {
          // clear selections list
          rewardsUIProps.setIds([]);
          // closing edit modal
          rewardsUIProps.closeEditRewardDialog();
        });
      });
    } else {
      // server request for updating rewards
      dispatch(actions.updateReward(reward)).then(() => {
        // refresh list after deletion
        dispatch(
          // refresh list after deletion
          actions.fetchRewards(
            rewardsUIProps.queryParams,
            rewardsUIProps.countryId
          )
        ).then(() => {
          // clear selections list
          rewardsUIProps.setIds([]);
          // closing edit modal
          rewardsUIProps.closeEditRewardDialog();
        });
      });
    }
  };

  return (
    <Modal
      show={rewardsUIProps.showEditRewardDialog}
      onHide={rewardsUIProps.closeEditRewardDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <RewardEditDialogHeader id={rewardsUIProps.id} />
      <RewardEditForm
        saveReward={saveReward}
        actionsLoading={actionsLoading}
        reward={rewardForEdit || rewardsUIProps.initReward}
        onHide={rewardsUIProps.closeEditRewardDialog}
      />
    </Modal>
  );
}
