import {createSlice} from "@reduxjs/toolkit";

const initialSocialconfigState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  socialconfigForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const socialconfigSlice = createSlice({
  name: "socialconfig",
  initialState: initialSocialconfigState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findSocialconfig
    socialconfigFetched: (state, action) => {
      
      state.actionsLoading = false;
      state.socialconfigForEdit = action.payload.socialconfigForEdit;
      state.error = null;
    },
    googleconfigFetched: (state, action) => {
      
      state.actionsLoading = false;
      state.googleconfigForEdit = action.payload.googleconfigForEdit;
      state.error = null;
    },
    twitterconfigFetched: (state, action) => {
      
      state.actionsLoading = false;
      state.twitterconfigForEdit = action.payload.twitterconfigForEdit;
      state.error = null;
    },
    
    // createSocialconfig
    socialconfigCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateSocialconfig
    socialconfigUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    
  }
});
