/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/rewardItems/rewardItemsActions";
import { useRewardItemsUIContext } from "./RewardItemsUIContext";

export function RewardItemDeleteDialog() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();
  const rewardItemsUIProps = useMemo(() => {
    return {
      id: rewardItemsUIContext.selectedId,
      setIds: rewardItemsUIContext.setIds,
      rewardId: rewardItemsUIContext.rewardId,
      queryParams: rewardItemsUIContext.queryParams,
      showDeleteRewardItemDialog: rewardItemsUIContext.showDeleteRewardItemDialog,
      closeDeleteRewardItemDialog: rewardItemsUIContext.closeDeleteRewardItemDialog,
    };
  }, [rewardItemsUIContext]);

  // RewardItems Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.rewardItems.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!rewardItemsUIProps.id) {
      rewardItemsUIProps.closeDeleteRewardItemDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardItemsUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteRewardItem = () => {
    // server request for deleting rewardItem by id
    dispatch(actions.deleteRewardItem(rewardItemsUIProps.id)).then(() => {
      // refresh list after deletion
      dispatch(
        actions.fetchRewardItems(
          rewardItemsUIProps.queryParams,
          rewardItemsUIProps.rewardId
        )
      );
      // clear selections list
      rewardItemsUIProps.setIds([]);
      // closing delete modal
      rewardItemsUIProps.closeDeleteRewardItemDialog();
    });
  };

  return (
    <Modal
      show={rewardItemsUIProps.showDeleteRewardItemDialog}
      onHide={rewardItemsUIProps.closeDeleteRewardItemDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          RewardItem Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this rewardItem?</span>
        )}
        {isLoading && <span>RewardItem is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={rewardItemsUIProps.closeDeleteRewardItemDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRewardItem}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
