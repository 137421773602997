import { makeStyles } from "@material-ui/styles";

const React = require("react");
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

function Upload(props) {
  const classes = useStyles();
  return (
    <>
      <p>{props.label}</p>
      <div className="input-group mb-3">
        <div className="custom-file">
          <input
            name={props.name}
            accept="image/*"
            className={`custom-file-input ${classes.input}`}
            type="file"
            id={props.name}
            onChange={props.onChange}
          />
          <label
            placeholder="Choose Image"
            className="custom-file-label"
            htmlFor={props.name}
          >
            {props.fileName}
          </label>
        </div>
      </div>
      {props.file && (
        <img
          alt="logo"
          src={props.file}
          width={props.width}
          height={props.height}
        />
      )}
    </>
  );
}
export default Upload;
