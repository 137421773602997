import React, { useEffect,useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/redeems/redeemsActions";
import { useSubheader } from "../../../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";

import { RedeemEditForm } from "./RedeemEditForm";

export function RedeemEditDialog({
  history,
  match: {
    params: { _id },
  },
}) {
  // Redeems UI Context
  const [title, setTitle] = useState("");
  const suhbeader = useSubheader();
  
const initRedeem= {
  _id: undefined,
  code: "",
  redeemEmail: "",
  rewardName: "",
  rewardCost: 0,
  rewardExtraInfo: "",
  redeemUser: "",
  subject:""
};
  // Redeems Redux state
  const dispatch = useDispatch();
  const { actionsLoading, redeemForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.redeems.actionsLoading,
      redeemForEdit: state.redeems.redeemForEdit,
    }),
    shallowEqual
  );



  useEffect(() => {
    // server call for getting Redeem by id
    dispatch(actions.fetchRedeem(_id));
  }, [_id, dispatch]);

  useEffect(() => {
    let _title = _id ? "Send Redeem" : "Send Redeem ";
    if (redeemForEdit && _id) {
      _title = `Send Redeem to ${redeemForEdit.redeemEmail}`;
    }

    setTitle(_title);
    suhbeader.setTitle("Send Redeem");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemForEdit, _id]);


  // server request for saving redeem
  const saveRedeem = (redeem) => {

    if (!_id) {
      // server request for creating redeem
      dispatch(actions.createRedeem(redeem)).then();
    } else {
      // server request for updating redeem
      dispatch(actions.updateRedeem(redeem)).then(()=>backToRedeemsList());
    }
  };

  const backToRedeemsList = () => {
    history.push(`/dash/redeems`);
  };

  return (
    <Card
    >
    {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-light"
            onClick={backToRedeemsList}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
     <RedeemEditForm 
       redeem={redeemForEdit || initRedeem}
       actionsLoading={actionsLoading}
       saveRedeem={saveRedeem}
       history={history}
     />
      </CardBody>
      
    </Card>
  );
}
