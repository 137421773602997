import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NotificationLoadingDialog } from "./notification-loading-dialog/NotificationLoadingDialog";
import * as actions from "../../_redux/notification/notificationActions";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { TextArea } from "../../../../../_metronic/_partials/controls/forms/TextArea";
import "./css/notification.css";

const notificationSchema = Yup.object().shape({
  notificationTitle: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Title is required"),
    notificationBody: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(400, "Maximum 400 symbols")
    .required("Description is required"),
});

const initGiveway = {
  id: undefined,
  notificationTitle: "",
  notificationBody: "",
  
};

export function NotificationPage({ history }) {
  const { actionsLoading, currentState } = useSelector(
    (state) => ({
      actionsLoading: state.notification.actionsLoading,
      currentState: state.notification,
    }),
    shallowEqual
  );

  const { user } = useSelector((state) => state.auth);
  const { data } = currentState;
  const [isSent,setIsSent] = useState(false);

  // Notification Redux state
  const dispatch = useDispatch();
  

  const saveNotification = (notification) => {
      notification.adminId = user._id;
      // server request for creating customer
      dispatch(actions.createNotification(notification)).then(() => {
        setIsSent(true);
      });
    
  };

  const DataFormik = data || initGiveway;

  return (
    <>
      <NotificationLoadingDialog />

      <Card className="mb-10">
        <Card.Body>
          <Card>
            <Card.Header
              
              style={{
                padding: "10px 0px 0px 0px",
                backgroundColor: "#1e1e2d"
              }}
            >
              <Container>
                <Row className="align-items-center">
                  <Col>
                    <h3
                      style={{
                        color: "white",
                        padding: "8px 0px",
                      }}
                    >
                      Send Push Notification
                    </h3>
                  </Col>
                </Row>
              </Container>
            </Card.Header>
            <Card.Body
              style={{
                padding: "10px 10px 10px 10px",
              }}
            >
              <Formik
                enableReinitialize={true}
                initialValues={DataFormik}
                validationSchema={notificationSchema}
                onSubmit={(values) => {
                  saveNotification(values);
                }}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <>
                    <Form className="form form-label-right">
                      <div className="col-lg-12 notification-formik">
                        <Field
                          name="notificationTitle"
                          component={Input}
                          placeholder="Giveway Title"
                          label="Giveway Title"
                        />
                      </div>
                      <div className="col-lg-12 notification-formik">
                        <Field
                          name="notificationBody"
                          row={4}
                          component={TextArea}
                          placeholder="Notification Description"
                          label="Notification Description"
                        />
                      </div>

                      
                    </Form>
                    {actionsLoading && (
                      <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                    )}
                    <Button
                      style={{ float: "right" }}
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      {!isSent ? "Send to All Devices": "Sent"}
                    </Button>{" "}
                  </>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </>
  );
}
