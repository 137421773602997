// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Card, Button, Form, Row, Col } from "react-bootstrap";

import * as Yup from "yup";
import {
  Input,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { Switch } from "@material-ui/core";

// Validation schema
const NetworkEditSchema = Yup.object().shape({
  appId: Yup.string()
    .min(1, "Minimum 1 symbol")
    .required("App Id is required"),
});

export function IpDataEditForm({
  saveNetwork,
  network,
  actionsLoading,
  history,
}) {
  const [check, setChecked] = useState(false);
  const [check1, setChecked1] = useState(false);

  const backToNetworkList = () => {
    history.push(`/dash/network`);
  };

  useEffect(() => {
    network.androidEnable === 0 ? setChecked(false) : setChecked(true);
    network.iosEnable === 0 ? setChecked1(false) : setChecked1(true);
  }, [network]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Card.Body>
        <Formik
          enableReinitialize={true}
          initialValues={network}
          validationSchema={NetworkEditSchema}
          onSubmit={(values) => {
            saveNetwork(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Form>
                <Row>
                  <Col>
                    <Field
                      name="appId"
                      component={Input}
                      placeholder="API KEY"
                      label="API KEY"
                    />
                  </Col>
                  
                </Row>
                &nbsp;
                <Row>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <label style={{ padding: "10px" }} htmlFor="switch">
                        Enable for Android:
                      </label>
                      <Switch
                        checked={check}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          let x;
                          e.target.checked ? (x = 1) : (x = 0);
                          setFieldValue("androidEnable", x);
                        }}
                        value="check"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <label style={{ padding: "10px" }} htmlFor="switch">
                        Enable for IOS:
                      </label>
                      <Switch
                        checked={check1}
                        onChange={(e) => {
                          setChecked1(e.target.checked);
                          let x;
                          e.target.checked ? (x = 1) : (x = 0);
                          setFieldValue("iosEnable", x);
                        }}
                        value="check1"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Col>
                </Row>
                &nbsp;
              </Form>
              <>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
                <Button onClick={backToNetworkList} variant="secondry">
                  Cancel
                </Button>
              </>
            </>
          )}
        </Formik>
      </Card.Body>
     
    </>
  );
}
