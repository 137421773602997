import {createSlice} from "@reduxjs/toolkit";

const initialOffersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  offerForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const offersSlice = createSlice({
  name: "offers",
  initialState: initialOffersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {


      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getOfferById
    offerFetched: (state, action) => {
      state.actionsLoading = false;
      state.offerForEdit = action.payload.offerForEdit;
      state.error = null;
    },
    // getOfferDetailsById
    offerDetailsFetched: (state, action) => {
      state.actionsLoading = false;
      state.offerDetails = action.payload.offerDetails;
      state.error = null;
    },
    // findOffers
    offersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createOffer
    offerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.offer);
    },
    // updateOffer
    offerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity._id === action.payload.offer._id) {
          return action.payload.offer;
        }
        return entity;
      });
    },
    // deleteOffer
    offerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // eslint-disable-next-line no-self-assign
      state.entities = state.entities;
    },
    // deleteOffers
    offersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // offersUpdateState
    offersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity._id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
