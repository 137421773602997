/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/rewards/rewardsActions";
import { useRewardsUIContext } from "./RewardsUIContext";

export function RewardDeleteDialog() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return {
      id: rewardsUIContext.selectedId,
      setIds: rewardsUIContext.setIds,
      countryId: rewardsUIContext.countryId,
      queryParams: rewardsUIContext.queryParams,
      showDeleteRewardDialog: rewardsUIContext.showDeleteRewardDialog,
      closeDeleteRewardDialog: rewardsUIContext.closeDeleteRewardDialog,
    };
  }, [rewardsUIContext]);

  // Rewards Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.rewards.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!rewardsUIProps.id) {
      rewardsUIProps.closeDeleteRewardDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardsUIProps.id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteReward = () => {
    // server request for deleting reward by id
    dispatch(actions.deleteReward(rewardsUIProps.id)).then(() => {
      // refresh list after deletion
      dispatch(
        actions.fetchRewards(
          rewardsUIProps.queryParams,
          rewardsUIProps.countryId
        )
      );
      // clear selections list
      rewardsUIProps.setIds([]);
      // closing delete modal
      rewardsUIProps.closeDeleteRewardDialog();
    });
  };

  return (
    <Modal
      show={rewardsUIProps.showDeleteRewardDialog}
      onHide={rewardsUIProps.closeDeleteRewardDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Reward Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this reward?</span>
        )}
        {isLoading && <span>Reward is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={rewardsUIProps.closeDeleteRewardDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteReward}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
