import * as requestFromServer from "./notificationCrud";
import {notificationSlice, callTypes} from "./notificationSlice";

const {actions} = notificationSlice;



export const createNotification = notificationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createNotification(notificationForCreation)
    .then(response => {
      const { notification } = response.data;
      dispatch(actions.notificationCreated(notification ));
    })
    .catch(error => {
      error.clientMessage = "Can't create notification";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


