import React, { useState } from "react";
import IdentityEditForm from "./components/IdentityEditForm";
import FacebookEditForm from "./components/FacebookEditForm";

import { SettingLoadingDialog } from "./setting-loading-dialog/SettingLoadingDialog";
import GoogleEditForm from "./components/GoogleEditForm";
import TwitterEditForm from "./components/TwitterEditForm";
import MailgunEditForm from "./components/MailgunEditForm";
import GeneralConfigEditForm from "./components/GeneralConfigEditForm";


export function SettingPage({ history }) {

  const [activeTab, saveCurrentTab] = useState(0);
  const [key, setKey] = useState(activeTab ? +activeTab : 0);
  
  return (
    <>
      <SettingLoadingDialog />
      <>
        <div className="card card-custom">
          {/*Header*/}
          <div className="card-header card-header-tabs-line">
            <ul
              className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
              data-remember-tab="tab_id"
              role="tablist"
            >
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 0 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(0);
                    saveCurrentTab(0);
                  }}
                >
                  Identity
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 1 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(1);
                    saveCurrentTab(1);
                  }}
                >
                  Facebook
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 2 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(2);
                    saveCurrentTab(2);
                  }}
                >
                  Google
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 3 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(3);
                    saveCurrentTab(3);
                  }}
                >
                  Twitter
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 4 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(4);
                    saveCurrentTab(4);
                  }}
                >
                  Mailgun
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 5 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(5);
                    saveCurrentTab(5);
                  }}
                >
                  General Config
                </span>
              </li>
            </ul>
          </div>
          <div className="form">
            <div className="card-body">
              <div className="tab-content pt-3">
                <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                  <IdentityEditForm />
                </div>
                <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
                  <FacebookEditForm name="facebook"/>
                </div>
                <div className={`tab-pane ${key === 2 ? "active" : ""}`}>
                  <GoogleEditForm name="google" />
                </div>
                <div className={`tab-pane ${key === 3 ? "active" : ""}`}>
                  <TwitterEditForm name="twitter" />
                </div>
                <div className={`tab-pane ${key === 4 ? "active" : ""}`}>
                  <MailgunEditForm name="mailgun" />
                </div>
                <div className={`tab-pane ${key === 5 ? "active" : ""}`}>
                  <GeneralConfigEditForm name="general" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
