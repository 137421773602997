import React, { useEffect, useState } from "react";
import Upload from "../components/Upload";
import { Input } from "../../../../../../../src/_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/setting/settingActions";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const Setting = {
  _id: undefined,
  appName: "",
  moto: "",
  domain: "",
  sharingDomain: "",
  endpoint: "",
};
const IdentityEditSchema = Yup.object().shape({
  appName: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("App Name is required"),
  moto: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Moto is required"),
  domain: Yup.string().required("Domain is required"),
  sharingDomain: Yup.string().required("Sharing Domain is required"),
  endpoint: Yup.string().required("Endpoint is required"),
  logo: Yup.mixed().required(),
  favicon: Yup.mixed().required(),
});
function IdentityEditForm() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { actionsLoading, settingForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.setting.actionsLoading,
      settingForEdit: state.setting.settingForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Network by id
    dispatch(actions.fetchSetting(user._id));
  }, [user, dispatch]);
  const saveSetting = (setting) => {
    if (!setting._id) {
      setting.adminId = user._id;
      // server request for creating network
      dispatch(actions.createSetting(setting)).then();
    } else {
      // server request for updating network
      dispatch(actions.updateSetting(setting)).then();
    }
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Choose Image");
  const [file1, setFile1] = useState(null);
  const [fileName1, setFileName1] = useState("Choose Image");

  useEffect(() => {
    if (settingForEdit) {
      setFile(settingForEdit.logo);
      setFile1(settingForEdit.favicon);
    }
  }, [user, settingForEdit]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={settingForEdit || Setting}
      validationSchema={IdentityEditSchema}
      onSubmit={(values) => {
        saveSetting(values);
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <>
          <Form className="form form-label-right ">
            <div className="form-group row">
              {/* Product Name */}
              <div className="col-lg-5">
                <Field
                  name="appName"
                  component={Input}
                  placeholder="App Name"
                  label="App Name"
                />
              </div>
              {/* Moto Name */}
              <div className="col-lg-5">
                <Field
                  name="moto"
                  component={Input}
                  placeholder="Ex : Discover Apps & Get Paid."
                  label="Moto"
                />
              </div>
            </div>

            <div className="form-group row">
              {/* Domain Name */}
              <div className="col-lg-5">
                <Field
                  name="domain"
                  component={Input}
                  placeholder="Ex : app.com"
                  label="Domain Name"
                />
              </div>
              {/* Domain Shortcut */}
              <div className="col-lg-5">
                <Field
                  name="sharingDomain"
                  component={Input}
                  placeholder="Ex : app.com"
                  label="Domain Shortcut For Sharing"
                />
              </div>
            </div>

            <div className="form-group row">
              {/* Endpoint Name */}
              <div className="col-lg-10">
                <Field
                  name="endpoint"
                  component={Input}
                  placeholder="Ex : https://appxxx.appspot.com"
                  label="Endpoint"
                />
              </div>
            </div>

            <div className="form-group row">
              {/* Endpoint Name */}
              <div className="col-lg-5">
                <Upload
                  label="Select App Logo"
                  fileName={fileName}
                  file={file}
                  name="logo"
                  width="150"
                  height="150"
                  onChange={(event) => {
                    setFieldValue("logo", event.currentTarget.files[0]);
                    setFileName(event.target.files[0].name);
                    setFile(URL.createObjectURL(event.target.files[0]));
                  }}
                />
              </div>
              <div className="col-lg-5">
                <Upload
                  label="Select Favicon"
                  fileName={fileName1}
                  file={file1}
                  name="favicon"
                  width="150"
                  height="150"
                  onChange={(event) => {
                    console.log(event.currentTarget);
                    setFieldValue("favicon", event.currentTarget.files[0]);
                    setFileName1(event.target.files[0].name);
                    setFile1(URL.createObjectURL(event.target.files[0]));
                  }}
                />
              </div>
            </div>
          </Form>
          <div className="form-group row">
            {/* Endpoint Name */}
            <div className="col-lg-6">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              {actionsLoading && (
                <div
                  style={{ float: "right" }}
                  className="overlay-layer bg-transparent"
                >
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default IdentityEditForm;
