import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DashboardLoadingDialog } from "./dashboard-loading-dialog/DashboardLoadingDialog";
import * as actions from "../../_redux/dashboard/dashboardActions";
import { UsersWidget } from "./components/stats/UsersWidget";
import { RevenueWidget } from "./components/stats/RevenueWidget";
import { RedeemWidget } from "./components/stats/RedeemWidget";
import { MixedWidget1 } from "./components/mixed/MixedWidget1";
import { LastCompletedOffer } from "./components/lists/LastCompletedOffers";
import { CompletedOffersWidget } from "./components/stats/CompletedOffersWidget";
import { LastRedeems } from "./components/lists/LastRedeems";
import { LastUsers } from "./components/lists/LastUsers";

export function DashboardPage({ history }) {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.dashboard }),
    shallowEqual
  );
  const { data } = currentState;


  // Dashboard Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    dispatch(actions.fetchDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <DashboardLoadingDialog />
      {data && (
        <div className="row">
          <div className="col-lg-6 col-xxl-4">
            <MixedWidget1
              todayOffers={data.todayOffers}
              todayUsers={data.todayUsers}
              todayRedeems={data.todayRedeems}
              todayRevenue={data.todayRevenue}
              className="card-stretch gutter-b"
            />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <UsersWidget
              users={data.userWidget.users}
              usersData={data.userWidget.usersData}
              className="card-stretch card-stretch-half gutter-b"
            />
            <RevenueWidget
              revenue={data.revenueWidget.revenue}
              revenueData={data.revenueWidget.revenueData}
              className="card-stretch card-stretch-half gutter-b"
            />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <RedeemWidget
              redeemCount={data.redeemWidget.redeemCount}
              redeemData={data.redeemWidget.redeemData}
              className="card-stretch card-stretch-half gutter-b"
            />
            <CompletedOffersWidget
              offers={data.offersWidget.offers}
              offersData={data.offersWidget.offersData}
              className="card-stretch card-stretch-half gutter-b"
            />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <LastUsers lastUsers={data.LastUsers} className="card-stretch gutter-b" />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <LastCompletedOffer lastOffers={data.LastCompletedOffers} className="card-stretch gutter-b" />
          </div>
          <div className="col-lg-6 col-xxl-4">
            <LastRedeems lastRedeems={data.LastRedeems} className="card-stretch gutter-b" />
          </div>
          
          
          
        </div>
      )}
    </>
  );
}
