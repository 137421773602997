import React, { useMemo } from "react";
import { RewardsFilter } from "./RewardsFilter";
import { RewardsTable } from "./RewardsTable";
import { RewardsLoadingDialog } from "./RewardsLoadingDialog";
import { RewardsDeleteDialog } from "./RewardsDeleteDialog";
import { RewardDeleteDialog } from "./RewardDeleteDialog";
import { RewardsFetchDialog } from "./RewardsFetchDialog";
import { RewardsGrouping } from "./RewardsGrouping";
import { RewardEditDialog } from "./reward-edit-dialog/RewardEditDialog";
import { useRewardsUIContext } from "./RewardsUIContext";

export function Rewards() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return { ids: rewardsUIContext.ids };
  }, [rewardsUIContext]);

  return (
    <>
      <RewardsLoadingDialog />
      <RewardEditDialog />
      <RewardDeleteDialog />
      <RewardsDeleteDialog />
      <RewardsFetchDialog />
      <div className="form margin-b-30">
        <RewardsFilter />
        {rewardsUIProps.ids.length > 0 && <RewardsGrouping />}
      </div>
      <RewardsTable />
    </>
  );
}
