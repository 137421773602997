import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { TextArea } from "../../../../../_metronic/_partials/controls/forms/TextArea";

import "./css/giveaway.css";
import { Switch } from "@material-ui/core";

const winnerSchema = Yup.object().shape({
  winnerTitle: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Title is required"),
  winnerDescription: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(400, "Maximum 400 symbols")
    .required("Description is required"),
});

export function WinnerForm({ history , winner,saveWinner }) {
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.winner.actionsLoading,
      currentState: state.winner,
    }),
    shallowEqual
  );

  const [check, setChecked] = useState(false);
  const [check1, setChecked1] = useState(false);
  const [showWinner, setShowWinner] = useState("none");

  useEffect(() => {
    winner.winnerStatus === 0 ? setChecked(false) : setChecked(true);
    winner.winnerStatus === 0 ? setChecked1(false) : setChecked1(true);
    winner.winnerStatus === 0 ? setShowWinner("none") : setShowWinner("");
  }, [winner]);
  return (
    <>
      <Card.Footer
        style={{
          padding: "10px 0px 0px 0px",
          backgroundColor: "#1e1e2d"
        }}
      >
        <Container>
          <Row className="align-items-center">
            <Col>
              <h3
                style={{
                  color: "white",
                }}
              >
                We Have Winners
              </h3>
            </Col>
            <Col md="auto">
              <Switch
                checked={check}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  e.target.checked ? setShowWinner("") : setShowWinner("none");
                }}
                value="check"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Col>
          </Row>
        </Container>
      </Card.Footer>
      <div style={{padding:"10px",display:showWinner}} className="card-body">
        <Formik
          enableReinitialize={true}
          initialValues={winner}
          validationSchema={winnerSchema}
          onSubmit={(values) => {
            saveWinner(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Form className="form form-label-right">
                <div className="col-lg-12 giveaway-formik">
                  <Field
                    name="winnerTitle"
                    component={Input}
                    placeholder="Winner Title"
                    label="Winner Title"
                  />
                </div>
                <div className="col-lg-12 giveaway-formik">
                  <Field
                    name="winnerDescription"
                    row={4}
                    component={TextArea}
                    placeholder="Winner Description"
                    label="Winner Description"
                  />
                </div>

                <div className="col-lg-12 giveaway-formik">
                  <div style={{ display: "flex" }}>
                    <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable:
                    </label>
                    <Switch
                      checked={check1}
                      onChange={(e) => {
                        setChecked1(e.target.checked);
                        let x;
                        e.target.checked ? (x = 1) : (x = 0);
                        setFieldValue("winnerStatus", x);
                      }}
                      value="check"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </div>
              </Form>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Button
                style={{ float: "right" }}
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </Button>{" "}
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
