/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, createContext, useState, useCallback } from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./RewardItemsUIHelper";

const RewardItemsUIContext = createContext();

export function useRewardItemsUIContext() {
  return useContext(RewardItemsUIContext);
}

export const RewardItemsUIConsumer = RewardItemsUIContext.Consumer;

export function RewardItemsUIProvider({ currentRewardId, children }) {
  const [rewardId, setRewardId] = useState(currentRewardId);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [showEditRewardItemDialog, setShowEditRewardItemDialog] = useState(false);
  const initRewardItem = {
    id: undefined,
    cost: "",
    points: 0,
    rewardId: rewardId
  };
  useEffect(()=> {
    initRewardItem.rewardId = currentRewardId;
    initRewardItem.carId = currentRewardId;
    setRewardId(currentRewardId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRewardId]);
  const openNewRewardItemDialog = () => {
    setSelectedId(undefined);
    setShowEditRewardItemDialog(true);
  };
  const openEditRewardItemDialog = id => {
    setSelectedId(id);
    setShowEditRewardItemDialog(true);
  };
  const closeEditRewardItemDialog = () => {
    setSelectedId(undefined);
    setShowEditRewardItemDialog(false);
  };
  const [showDeleteRewardItemDialog, setShowDeleteRewardItemDialog] = useState(false);
  const openDeleteRewardItemDialog = id => {
    setSelectedId(id);
    setShowDeleteRewardItemDialog(true);
  };
  const closeDeleteRewardItemDialog = () => {
    setSelectedId(undefined);
    setShowDeleteRewardItemDialog(false);
  };

  const [showDeleteRewardItemsDialog, setShowDeleteRewardItemsDialog] = useState(false);
  const openDeleteRewardItemsDialog = () => {
    setShowDeleteRewardItemsDialog(true);
  };
  const closeDeleteRewardItemsDialog = () => {
    setShowDeleteRewardItemsDialog(false);
  };

  const [showFetchRewardItemsDialog, setShowFetchRewardItemsDialog] = useState(false);
  const openFetchRewardItemsDialog = () => {
    setShowFetchRewardItemsDialog(true);
  };
  const closeFetchRewardItemsDialog = () => {
    setShowFetchRewardItemsDialog(false);
  };

  const value = {
    ids,
    setIds,
    rewardId,
    setRewardId,
    queryParams,
    setQueryParams,
    initRewardItem,
    selectedId,
    showEditRewardItemDialog,
    openNewRewardItemDialog,    
    openEditRewardItemDialog,
    closeEditRewardItemDialog,
    showDeleteRewardItemDialog,
    openDeleteRewardItemDialog,
    closeDeleteRewardItemDialog,
    showDeleteRewardItemsDialog,
    openDeleteRewardItemsDialog,
    closeDeleteRewardItemsDialog,
    openFetchRewardItemsDialog,
    closeFetchRewardItemsDialog,
    showFetchRewardItemsDialog
  };
  
  return (
    <RewardItemsUIContext.Provider value={value}>
      {children}
    </RewardItemsUIContext.Provider>
  );
}
