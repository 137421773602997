// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Card, Button, Form, Row, Col } from "react-bootstrap";

import * as Yup from "yup";
import { Input, ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

import Ckeditor from "./ckEditor/Ckeditor";
import { useSelector } from "react-redux";

// Validation schema
const RedeemEditSchema = Yup.object().shape({
  code: Yup.string()
    .min(1, "Minimum 1 symbol")
    .required("Gift Card Code is required"),
  rewardName: Yup.string()
    .min(0, "Minimum 0")
    .max(100, "Maximum 100")
    .required("Reward Name is required"),
  redeemEmail: Yup.string()
    .email("Wrong Email format")
    .required("Email is required"),
  subject: Yup.string().required("Subject is required"),
});

export function RedeemEditForm({
  saveRedeem,
  redeem,
  actionsLoading,
  history,
}) {
  const [text, setText] = useState("");
  const { user } = useSelector((state) => state.auth);

  useEffect(()=>{
    redeem.text && setText(redeem.text);
  },[redeem]);

  const backToRedeemsList = () => {
    history.push(`/dash/redeems`);
  };
  return (
    <>

{actionsLoading && <ModalProgressBar />}
      <Card.Body>
        <Formik
          enableReinitialize={true}
          initialValues={redeem}
          validationSchema={RedeemEditSchema}
          onSubmit={(values) => {
            values.text = text;
            saveRedeem({...values, adminId: user._id});
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Form>
                <Row>
                  <Col>
                    <Field
                      type="text"
                      name="code"
                      component={Input}
                      placeholder="AXZ5S-HG4ML-4KLMA"
                      label="Gift Card Code"
                    />
                  </Col>
                  <Col>
                    <Field
                      type="text"
                      name="rewardName"
                      component={Input}
                      placeholder="10$ Amazon Gift Card"
                      label="Reward Name"
                    />
                  </Col>
                </Row>
                &nbsp;
                <Row>
                  <Col>
                    <Form.Label>Extra Info</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formBasicCheckbox ml-3">
                      <Form.Text>
                        <p>{redeem && redeem.rewardExtraInfo} </p>
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      name="redeemEmail"
                      component={Input}
                      placeholder="Receiver Email"
                      label="Receiver Email"
                    />
                  </Col>
                  <Col>
                    <Field
                      type="text"
                      name="subject"
                      component={Input}
                      placeholder="Subject"
                      label="Subject"
                    />
                  </Col>
                </Row>
                &nbsp;
                <Row>
                  <Col>
                    <Ckeditor
                      data={redeem.text}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setText(data);
                      }}
                    />
                  </Col>
                </Row>
                &nbsp;

              </Form>
              <>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Send Reward
                </Button>
                <Button onClick={backToRedeemsList} variant="secondry">
                  Cancel
                </Button>
              </>
            </>
          )}
        </Formik>
      </Card.Body>
    </>
  );
}
