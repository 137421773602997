import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { RedeemsFilter } from "./redeems-filter/RedeemsFilter";
import { RedeemsTable } from "./redeems-table/RedeemsTable";
import { RedeemsGrouping } from "./redeems-grouping/RedeemsGrouping";
import { useRedeemsUIContext } from "./RedeemsUIContext";

export function RedeemsCard() {
  const redeemsUIContext = useRedeemsUIContext();
  const redeemsUIProps = useMemo(() => {
    return {
      ids: redeemsUIContext.ids,
      newRedeemButtonClick: redeemsUIContext.newRedeemButtonClick,
    };
  }, [redeemsUIContext]);

  return (
    <Card>
      <CardHeader title="Redeems">
      </CardHeader>
      <CardBody>
        <RedeemsFilter />
        {redeemsUIProps.ids.length > 0 && <RedeemsGrouping />}
        <RedeemsTable />
      </CardBody>
    </Card>
  );
}
