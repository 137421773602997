// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditRedeemDialog,
    openFetchRedeemsDetailsDialog,
    openDeleteRedeemDialog,
    openPhoneVerificationDialog,
      openCryptoPaymentPage
  }
) {
  return (
    <>
      <a
        title="Send Phone Verification Mail"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openPhoneVerificationDialog(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-warning">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Outgoing-call.svg")} />
        </span>
      </a>
        <a
            title="Send Crypto Payment "
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => openCryptoPaymentPage(row._id, row.redeemUser)}
        >
        <span className="svg-icon svg-icon-md svg-icon-warning">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bitcoin.svg")} />
        </span>
        </a>
      <a
        title="Pay Reward"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditRedeemDialog(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-success">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
        </span>
      </a>
      <a
        title="Cancel Reward Request"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteRedeemDialog(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
        </span>
      </a>
      <> </>
      <a
        title="Delete redeem"
        className="btn btn-icon btn-light btn-hover-info btn-sm"
        onClick={() => openFetchRedeemsDetailsDialog(row.redeemUser)}
      >
        <span className="svg-icon svg-icon-md svg-icon-info">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Other1.svg")} />
        </span>
      </a>
    </>
  );
}
