import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {Table} from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/redeems/redeemsActions";

export function RedeemFetchDetails({ id, show, onHide }) {
  // Redeems UI Context

  // Redeems Redux state
  const dispatch = useDispatch();
  const { actionsLoading, redeemDetails } = useSelector(
    (state) => ({
      actionsLoading: state.redeems.actionsLoading,
      redeemDetails: state.redeems.redeemDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Redeem by id
    dispatch(actions.fetchRedeemDetails(id));
  }, [id, dispatch]);

  const PrettyPrintJson = React.memo(({data}) => (<div><pre>{
    JSON.stringify(data, null, 2) }</pre></div>));

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {actionsLoading && <ModalProgressBar />}
      {/*end::Loading*/}

      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">User Details</Modal.Title>
      </Modal.Header>
      {redeemDetails && (
        <Modal.Body>
          <h3>Details</h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Email </th>
                <th>From GPLUS</th>
                <th>From FB</th>
                <th>Created At</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{redeemDetails.username}</td>
                <td>{redeemDetails.googleId}</td>
                <td>{redeemDetails.facebookId}</td>
                <td>{redeemDetails.createdAt}</td>
                <td>{redeemDetails.phone}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Device Class</th>
                <th>Network Size</th>
                <th>Network Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{redeemDetails.device}</td>
                <td>{redeemDetails.usersReferedCount}</td>
                <td>{redeemDetails.sum} P</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>IP</th>
                <th>Geo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{redeemDetails.ipOfSignup}</td>
                <td>
                  <ReactCountryFlag
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    title={redeemDetails.geo}
                    countryCode={redeemDetails.geo}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <h3>Last 10 Completed Offers</h3>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Offer Name</th>
                <th>Offer Payout</th>
                <th>Status</th>
                <th>Network</th>
                <th>CreatedAt</th>
              </tr>
            </thead>
            <tbody> 
            {
                redeemDetails.completedOffers.map((item)=>{
                  return (
                    <tr key={item._id}>
                <td>{item._id}</td>
                <td>{item.offerName}</td>
                <td>{item.offerPayout}</td>
                <td>{item.type}</td>
                <td>{item.network}</td>
                <td>{new Date(item.date).toLocaleString()}</td>
              </tr>
                  );
                })
            }
              
              
            </tbody>
          </Table>
          <h3>Android Devices</h3>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Device Name</th>
                <th>BSSID</th>
                <th>GAID</th>
                <th>Locale</th>
                <th>Resolution</th>
                <th>Country code</th>
                <th>carrier_country</th>
                <th>OS version</th>
                <th>Android ID</th>
              </tr>
            </thead>
            <tbody> 
            {
              redeemDetails.devices?.map((item)=>{
                   return (
                    <tr key={item._id}>
                    <td>{item.device_name}</td>
                <td>{item.bssid}</td>
                <td>{item.gaid}</td>
                <td>{item.locale}</td>
                <td>{item.resolution}</td>
                <td>{item.country_code}</td>
                <td>{item.carrier_country}</td>
                <td>{item.osversion}</td>
                <td>{item.androidId}</td>
              </tr>
                   );
                })
            }
              
              
            </tbody>
          </Table>

          <h3>Android Devices Info</h3>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Fingerprint</th>
                <th>isEmulator</th>
                <th>deviceInfo</th>
                <th>integrityInfo</th>
              </tr>
            </thead>
            <tbody>
              {redeemDetails.devicesInfo?.map((item, i) => {
                const info = item.deviceInfo.replaceAll("___", "<br>");
                return (
                  <tr key={i}>
                    <td>{item.fingerprintId}</td>
                    <td>{item.isEmulator.toString()}</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: info,
                      }}
                    ></td>
                    <td>
                      <PrettyPrintJson data={item.integrityInfo} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <h3>Security Fraud Detector</h3>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Webrtc ips</th>
                <th>tcpipData</th>
                <th>timezone</th>
                <th>date</th>
                <th>userAgent</th>
                <th>ipTimezone</th>
              </tr>
            </thead>
            <tbody>
              {redeemDetails.securityData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.ips.toString()}</td>
                    <td>{<PrettyPrintJson data={item.tcpData} />}</td>
                    <td>{item.timezone}</td>
                    <td>{item.date}</td>
                    <td>{item.userAgent}</td>
                    <td>
                      <PrettyPrintJson data={item.ipTimezone} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <>
          <h3>Fraud Detection</h3>
          <PrettyPrintJson data={ redeemDetails.fraudDetection } />
          <h3>Bitlabs Data</h3>
          <PrettyPrintJson data={ redeemDetails.bitlabsInfo } />
          <h3>FingerPrint Data</h3>
          <PrettyPrintJson data={ redeemDetails.userInfo } />
          </>
          <h3>Monocle Info Data</h3>
          <PrettyPrintJson data={ redeemDetails.monocleInfo } />
        </Modal.Body>
      )}
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Ok
          </button>
          <> </>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
