import axios from "axios";

export const CUSTOMERS_URL = "api/setting";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSetting(admin) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/setting/${admin}`);
}

export function updateSetting(setting) {
  var bodyFormData = new FormData();
  bodyFormData.append("appName", setting.appName);
  bodyFormData.append("moto", setting.moto);
  bodyFormData.append("domain", setting.domain);
  bodyFormData.append("sharingDomain", setting.sharingDomain);
  bodyFormData.append("endpoint", setting.endpoint);
  bodyFormData.append("adminId", setting.adminId);
  bodyFormData.append("logo", setting.logo);
  bodyFormData.append("favicon", setting.favicon);
  return axios.put(`${process.env.REACT_APP_API_URL}/api/setting/${setting._id}`, bodyFormData );
}

export function createSetting(setting) {
  var bodyFormData = new FormData();
  bodyFormData.append("appName", setting.appName);
  bodyFormData.append("moto", setting.moto);
  bodyFormData.append("domain", setting.domain);
  bodyFormData.append("sharingDomain", setting.sharingDomain);
  bodyFormData.append("endpoint", setting.endpoint);
  bodyFormData.append("adminId", setting.adminId);
  bodyFormData.append("logo", setting.logo);
  bodyFormData.append("favicon", setting.favicon);

  return axios.post(`${process.env.REACT_APP_API_URL}/api/setting/`, bodyFormData);
}

///////////////////////////


export function findMailgunConfig(admin) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/setting/mailgun/${admin}`);
}

export function updateMailgunConfig(mailgunConfig) {
  
  return axios.put(`${process.env.REACT_APP_API_URL}/api/setting/mailgun/${mailgunConfig._id}`, mailgunConfig );
}

export function createMailgunConfig(mailgunConfig) {


  return axios.post(`${process.env.REACT_APP_API_URL}/api/setting/mailgun/`, mailgunConfig);
}
