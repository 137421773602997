import * as requestFromServer from "./redeemsCrud";
import { redeemsSlice, callTypes } from "./redeemsSlice";

const { actions } = redeemsSlice;

export const fetchRedeems = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findRedeems(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      dispatch(actions.redeemsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find redeems";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRedeem = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.redeemFetched({ redeemForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRedeemById(_id)
    .then((response) => {
      const redeem = response.data;
      dispatch(actions.redeemFetched({ redeemForEdit: redeem }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find redeem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRedeemFraudData = (redeemId, userId) => (dispatch) => {
  if (!redeemId || !userId) {
    return dispatch(actions.redeemFraudData({ redeemFraudData: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRedeemFraudData(redeemId, userId)
    .then((response) => {
      const fraudData = response.data.data;
      dispatch(actions.redeemFraudData({ redeemFraudData: fraudData }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find Redeem fraud data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRedeemDetails = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.redeemDetailsFetched({ redeemDetails: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRedeemDetailsById(_id)
    .then((response) => {
      const redeem = response.data;
      dispatch(actions.redeemDetailsFetched({ redeemDetails: redeem }));
    })
    .catch((error) => {
      error.clientMessage = "Can't load redeem details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRedeem = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRedeem(_id)
    .then((response) => {
      dispatch(actions.redeemDeleted({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't cancel redeem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const sendPhoneVerificationMail = (
  redeemId,
  userId,
  redeemEmail,
  adminId
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendPhoneVerificationMail(redeemId, userId, redeemEmail, adminId)
    .then((response) => {
      dispatch(actions.phoneVerificationDone());
    })
    .catch((error) => {
      error.clientMessage = "Can't send Mail";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const sendCryptoPayment = (redeemId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendCryptoPayment(redeemId)
    .then((response) => {
      dispatch(actions.paymentSent({result: response.data}));
    })
    .catch((error) => {

      error = JSON.stringify(error?.response?.data);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRedeem = (redeemForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRedeem(redeemForCreation)
    .then((response) => {
      const { redeem } = response.data;
      dispatch(actions.redeemCreated({ redeem }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create redeem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRedeem = (redeem) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRedeem(redeem)
    .then(() => {
      dispatch(actions.redeemUpdated({ redeem }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update redeem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRedeemsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRedeems(ids, status)
    .then(() => {
      dispatch(actions.redeemsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update redeems status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRedeems = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRedeems(ids)
    .then(() => {
      dispatch(actions.redeemsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete redeems";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
