import React, { useEffect } from "react";
import { Modal, Table } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/offers/offersActions";

export function OfferFetchDetails({ id, show, onHide }) {
  // Offers UI Context

  // Offers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, offerDetails } = useSelector(
    (state) => ({
      actionsLoading: state.offers.actionsLoading,
      offerDetails: state.offers.offerDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Offer by id
    dispatch(actions.fetchOfferDetails(id));
  }, [id, dispatch]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {actionsLoading && <ModalProgressBar />}
      {/*end::Loading*/}

      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">User Details</Modal.Title>
      </Modal.Header>
      {offerDetails && (
        <Modal.Body>
          <h3>Details</h3>
          <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Email </th>
                <th>From GPLUS</th>
                <th>From FB</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{offerDetails.username}</td>
                <td>{offerDetails.googleId}</td>
                <td>{offerDetails.facebookId}</td>
                <td>{offerDetails.createdAt}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Device Class</th>
                <th>Network Size</th>
                <th>Network Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{offerDetails.device}</td>
                <td>{offerDetails.usersReferedCount}</td>
                <td>{offerDetails.sum} P</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>IP</th>
                <th>Geo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{offerDetails.ipOfSignup}</td>
                <td>
                  <ReactCountryFlag
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    title={offerDetails.geo}
                    countryCode={offerDetails.geo}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <h3>Last 10 Completed Offers</h3>

          <Table striped bordered hover size="md">
            <thead>
              <tr>
                <th>Id</th>
                <th>Offer Name</th>
                <th>Offer Payout</th>
                <th>Network</th>
                <th>CreatedAt</th>
              </tr>
            </thead>
            <tbody> 
            {
                offerDetails.completedOffers.map((item)=>{
                  return (
              <tr key={item._id}>
                <td>{item._id}</td>
                <td>{item.offerName}</td>
                <td>{item.offerPayout}</td>
                <td>{item.network}</td>
                <td>{new Date(item.date).toLocaleString()}</td>
              </tr>
                  );
                })
            }
              
              
            </tbody>
          </Table>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Ok
          </button>
          <> </>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
