/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

import * as actions from "../../../_redux/redeems/redeemsActions";
import {
  RedeemStatusCssClasses,
  RedeemStatusTitles,
} from "../RedeemsUIHelpers";

const PrettyPrintJson = React.memo(({ data }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
));

const formatDeviceStringData = (d) => {
  var properties = d?.split("___");
  var obj = {};
  for (const property of properties) {
    var tup = property.split(":");
    obj[tup[0]] = tup[1];
  }
  return obj;
};

function StatusColumnFormatter({ status }) {
  console.log(status);
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${RedeemStatusCssClasses[status]} label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>{RedeemStatusTitles[status]}</span>
  );
}

function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function RedeemPaymentPage() {
  const params = useParams();

  // get admin
  const { user } = useSelector((state) => state.auth);

  // Redeems Redux state
  const dispatch = useDispatch();
  const {
    actionsLoading,
    redeemFraudData,
    phoneVerificationDone,
    redeemStatusUpdate,
    paymentSent,
    error,
  } = useSelector(
    (state) => ({
      actionsLoading: state.redeems.actionsLoading,
      redeemFraudData: state.redeems.redeemFraudData,
      phoneVerificationDone: state.redeems.phoneVerificationDone,
      redeemStatusUpdate: state.redeems.redeemStatusUpdate,
      paymentSent: state.redeems.paymentSent,
      error: state.redeems.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Redeem by id
    dispatch(actions.fetchRedeemFraudData(params._id, params.userId));
  }, [params, dispatch, phoneVerificationDone, redeemStatusUpdate]);

  const sendPhoneVerificationMail = () => {
    const { redeemEmail, redeemUser, _id } = redeemFraudData?.redeemData;
    topFunction();
    dispatch(
      actions.sendPhoneVerificationMail(_id, redeemUser, redeemEmail, user._id)
    );
  };

  const holdPayment = () => {
    const { _id } = redeemFraudData?.redeemData;
    topFunction();
    dispatch(actions.updateRedeemsStatus([_id], 3));
  };

  const sendCryptoPayment = () => {
    const { _id } = redeemFraudData?.redeemData;
    topFunction();
    dispatch(actions.sendCryptoPayment(_id));
  };

  console.log(redeemStatusUpdate);

  return (
    <div>
      {actionsLoading && <ModalProgressBar />}
      <div className="pb-2">
        {error && <Alert severity="error">{error}</Alert>}
        {paymentSent && <PrettyPrintJson data={paymentSent}></PrettyPrintJson>}
        <h2>Redeem data:</h2>
        <h4 className="">
          Status:{" "}
          <StatusColumnFormatter status={redeemFraudData?.redeemData?.status} />
        </h4>
        <h4>Email : {redeemFraudData?.redeemData?.redeemEmail}</h4>
        <h4>
          Reward :{" "}
          {redeemFraudData?.redeemData?.rewardAmount + 
            " (" + 
            redeemFraudData?.redeemData?.rewardCost +
            " Points) " +
            redeemFraudData?.redeemData?.rewardName}
        </h4>
      </div>
      <h2>Fraud Detection Report:</h2>
      <h4 className="pb-2">
        IsFraud:{" "}
        <span style={{ color: redeemFraudData?.isFraud ? "red" : "green" }}>
          {redeemFraudData?.isFraud.toString()}
        </span>
      </h4>
      <h4 className="pb-2">
        Fraud reasons:
        {redeemFraudData?.fraudReason.map((x, i) => {
          return (
            <p key={i} className="pt-1 font-medium">
              {x}
            </p>
          );
        })}
      </h4>
      <h4 className="pb-2">
        Countries:
        {redeemFraudData?.countries.map((x, i) => {
          return (
            <p key={i} className="pt-1 font-medium">
              {x || "Null"}
            </p>
          );
        })}
      </h4>
      <h5 className="pb-2">
        User with same paypal account:
        <span className="pl-2">
          {redeemFraudData?.userWithSamePaypalExist?.toString()}
        </span>
      </h5>
      <h5 className="pb-2">
        User with same phone number:
        <span className="pl-2">
          {redeemFraudData?.userWithSamePhoneExist?.toString()}
        </span>
      </h5>
      <h5 className="pb-2">
        User with same fingerprint browser:
        <span className="pl-2">
          {redeemFraudData?.fingerPrintExist?.toString()}
        </span>
      </h5>
      <h4 className="pb-2">
        TCP and User agent detector:
        {redeemFraudData?.securityData.map((x, i) => {
          return <PrettyPrintJson key={i} data={x}></PrettyPrintJson>;
        })}
      </h4>
      <h4 className="pb-2">
        Android devices Integraty detector:
        {redeemFraudData?.devicesInfo.map((x, i) => {
          console.log(formatDeviceStringData(x.deviceInfo));
          const d = { ...x, deviceInfo: formatDeviceStringData(x.deviceInfo) };
          return <PrettyPrintJson key={i} data={d}></PrettyPrintJson>;
        })}
      </h4>
      <h4 className="pb-2">
        Frauds detector:
        {redeemFraudData?.frauds.map((x, i) => {
          return <PrettyPrintJson key={i} data={x}></PrettyPrintJson>;
        })}
      </h4>
      <h4 className="pb-2">
        Ips Duplicated (not to use just to Know):
        {redeemFraudData?.ipsDuplicated.map((x, i) => {
          return (
            <p key={i} className="pt-1 font-medium">
              {x || "Null"}
            </p>
          );
        })}
      </h4>
      <div>
        <h4 className="pb-2">Phone Number data: </h4>
        <h5 className="pb-2">Phone Number: {redeemFraudData?.phone}</h5>
        <PrettyPrintJson data={redeemFraudData?.phoneData}></PrettyPrintJson>
      </div>
      <h4 className="pb-2">
        Last 10 completed offers:
        {redeemFraudData?.offers.map((x, i) => {
          return <PrettyPrintJson key={i} data={x}></PrettyPrintJson>;
        })}
      </h4>
        <h3>Monocle Info Data: </h3>
        <PrettyPrintJson data={ redeemFraudData?.monocleInfo } />
      <h4 className="pt-4">Actions</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Button
          onClick={sendCryptoPayment}
          disabled={actionsLoading}
          variant="contained"
          color="primary"
        >
          Send Payment
        </Button>
        <Button
          onClick={sendPhoneVerificationMail}
          disabled={actionsLoading}
          variant="outlined"
          color="secondary"
        >
          Send Phone verification
        </Button>
        <Button
          onClick={holdPayment}
          disabled={actionsLoading}
          variant="outlined"
          color="default"
        >
          Hold payment
        </Button>
      </div>
    </div>
  );
}

export default RedeemPaymentPage;
