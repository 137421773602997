import axios from "axios";

export const CUSTOMERS_URL = "api/network";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findNetwork(name,admin) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/network/${name}/${admin}`);
}

export function updateNetwork(network) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/network/${network._id}`, network );
}

export function createNetwork(network) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/network/`, network);
}

