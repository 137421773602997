import React, { useEffect } from "react";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/socialconfig/socialconfigActions";
import { TextArea } from "../../../../../../_metronic/_partials/controls/forms/TextArea";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Card } from "react-bootstrap";

const Socialconfig = {
  _id: undefined,
  appId: "",
  appSecret: "",
  appPage: "",
  shareText: "",
};
const SocialconfigEditSchema = Yup.object().shape({
  appPage: Yup.string().required("App Page is required"),
  shareText: Yup.string().required("Share Text is required"),
});
function TwitterEditForm({name}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { actionsLoading, twitterconfigForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.socialconfig.actionsLoading,
      twitterconfigForEdit: state.socialconfig.twitterconfigForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Network by id
    dispatch(actions.fetchTwitterconfig(user._id));
  }, [user, dispatch]);
  const saveSocialconfig = (socialconfig) => {
    if (!socialconfig._id) {
      socialconfig.adminId = user._id;
      socialconfig.name=name;
      // server request for creating network
      dispatch(actions.createSocialconfig(socialconfig)).then();
    } else {
      // server request for updating network
      dispatch(actions.updateSocialconfig(socialconfig)).then();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={twitterconfigForEdit || Socialconfig}
      validationSchema={SocialconfigEditSchema}
      onSubmit={(values) => {
        
        saveSocialconfig(values);
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Form className="form form-label-right ">
            <Card.Footer>
            <div className="form-group row">
              {/* Domain Name */}
              <div className="col-lg-10">
                <Field
                  name="appPage"
                  component={Input}
                  placeholder="Twitter Page"
                  label="Twitter Page"
                />
              </div>
              {/* Domain Shortcut */}
              <div className="col-lg-10">
                <Field
                  name="shareText"
                  component={TextArea}
                  placeholder="Share message"
                  label="Share message"
                />
              </div>
            </div>
            </Card.Footer>
          </Form>
          <div className="form-group row">
            {/* Endpoint Name */}
            <div className="col-lg-6">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              {actionsLoading && (
                <div
                  style={{ float: "right" }}
                  className="overlay-layer bg-transparent"
                >
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default TwitterEditForm;
