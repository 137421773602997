import * as requestFromServer from "./giveawayCrud";
import {giveawaySlice, callTypes} from "./giveawaySlice";

const {actions} = giveawaySlice;

export const fetchGiveaway = (_id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findGiveaway(_id)
    .then(response => {

      const data = response.data;
      dispatch(actions.giveawayFetched({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't load giveaway";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createGiveaway = giveawayForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createGiveaway(giveawayForCreation)
    .then(response => {
      const { giveaway } = response.data;
      dispatch(actions.giveawayCreated(giveaway ));
    })
    .catch(error => {
      error.clientMessage = "Can't create giveaway";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateGiveaway = giveaway => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateGiveaway(giveaway)
    .then(() => {
      dispatch(actions.giveawayUpdated(giveaway ));
    })
    .catch(error => {
      error.clientMessage = "Can't update giveaway";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
