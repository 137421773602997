import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../_redux/rewardItems/rewardItemsActions";
import { RewardItemEditDialogHeader } from "./RewardItemEditDialogHeader";
import { RewardItemEditForm } from "./RewardItemEditForm";
import { useRewardItemsUIContext } from "../RewardItemsUIContext";


export function RewardItemEditDialog() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();
  const rewardItemsUIProps = useMemo(() => {
    return {
      id: rewardItemsUIContext.selectedId,
      setIds: rewardItemsUIContext.setIds,
      rewardId: rewardItemsUIContext.rewardId,
      queryParams: rewardItemsUIContext.queryParams,
      showEditRewardItemDialog: rewardItemsUIContext.showEditRewardItemDialog,
      closeEditRewardItemDialog: rewardItemsUIContext.closeEditRewardItemDialog,
      initRewardItem: rewardItemsUIContext.initRewardItem,
    };
  }, [rewardItemsUIContext]);

  // RewardItems Redux state
  const dispatch = useDispatch();
  const { actionsLoading, rewardItemForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.rewardItems.actionsLoading,
      rewardItemForEdit: state.rewardItems.rewardItemForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting rewardItem by seleted id
    dispatch(actions.fetchRewardItem(rewardItemsUIProps.id));
  }, [rewardItemsUIProps.id, dispatch]);

  const saveRewardItem = (rewardItem) => {
    if (!rewardItemsUIProps.id) {
      // server request for creating rewardItems
      dispatch(actions.createRewardItem(rewardItem)).then(() => {
        // refresh list after deletion
        dispatch(
          actions.fetchRewardItems(
            rewardItemsUIProps.queryParams,
            rewardItemsUIProps.rewardId
          )
        ).then(() => {
          // clear selections list
          rewardItemsUIProps.setIds([]);
          // closing edit modal
          rewardItemsUIProps.closeEditRewardItemDialog();
        });
      });
    } else {
      // server request for updating rewardItems
      dispatch(actions.updateRewardItem(rewardItem)).then(() => {
        // refresh list after deletion
        dispatch(
          // refresh list after deletion
          actions.fetchRewardItems(
            rewardItemsUIProps.queryParams,
            rewardItemsUIProps.rewardId
          )
        ).then(() => {
          // clear selections list
          rewardItemsUIProps.setIds([]);
          // closing edit modal
          rewardItemsUIProps.closeEditRewardItemDialog();
        });
      });
    }
  };

  return (
    <Modal
      show={rewardItemsUIProps.showEditRewardItemDialog}
      onHide={rewardItemsUIProps.closeEditRewardItemDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <RewardItemEditDialogHeader id={rewardItemsUIProps.id} />
      <RewardItemEditForm
        saveRewardItem={saveRewardItem}
        actionsLoading={actionsLoading}
        rewardItem={rewardItemForEdit || rewardItemsUIProps.initRewardItem}
        onHide={rewardItemsUIProps.closeEditRewardItemDialog}
      />
    </Modal>
  );
}
