import {createSlice} from "@reduxjs/toolkit";

const initialSettingState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  settingForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const settingSlice = createSlice({
  name: "setting",
  initialState: initialSettingState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findSetting
    settingFetched: (state, action) => {
      state.actionsLoading = false;
      state.settingForEdit = action.payload.settingForEdit;
      state.error = null;
    },
    // createSetting
    settingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateSetting
    settingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    mailgunConfigFetched: (state, action) => {
      state.actionsLoading = false;
      state.mailgunconfigForEdit = action.payload.mailgunconfigForEdit;
      state.error = null;
    },
    // createSetting
    mailgunConfigCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateSetting
    mailgunConfigUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    
  }
});
