import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useRewardItemsUIContext } from "./RewardItemsUIContext";

const selectedRewardItems = (entities, ids) => {
  const _rewardItems = [];
  ids.forEach((id) => {
    const rewardItem = entities.find((el) => el.id === id);
    if (rewardItem) {
      _rewardItems.push(rewardItem);
    }
  });
  return _rewardItems;
};

export function RewardItemsFetchDialog() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();
  const rewardItemsUIProps = useMemo(() => {
    return {
      ids: rewardItemsUIContext.ids,
      queryParams: rewardItemsUIContext.queryParams,
      showFetchRewardItemsDialog: rewardItemsUIContext.showFetchRewardItemsDialog,
      closeFetchRewardItemsDialog: rewardItemsUIContext.closeFetchRewardItemsDialog,
    };
  }, [rewardItemsUIContext]);

  const { rewardItems } = useSelector(
    (state) => ({
      rewardItems: selectedRewardItems(state.rewardItems.entities, rewardItemsUIProps.ids),
    }),
    shallowEqual
  );


  useEffect(() => {
    if (!rewardItemsUIProps.ids || rewardItemsUIProps.ids.length === 0) {
      rewardItemsUIProps.closeFetchRewardItemsDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardItemsUIProps.ids]);

  return (
    <Modal
      show={rewardItemsUIProps.showFetchRewardItemsDialog}
      onHide={rewardItemsUIProps.closeFetchRewardItemsDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {rewardItems.map((rewardItem) => (
              <div className="list-timeline-item mb-3" key={rewardItem._id}>
                <span className="list-timeline-text">
                  <span
                    className="label label-lg label-light-success label-inline"
                    style={{ width: "60px" }}
                  >
                    ID: {rewardItem._id}
                  </span>{" "}
                  <span className="ml-5">{rewardItem.cost} </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={rewardItemsUIProps.closeFetchRewardItemsDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={rewardItemsUIProps.closeFetchRewardItemsDialog}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
