import axios from "axios";

export const CUSTOMERS_URL = "api/settingconfig";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSettingconfig(admin) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/setting/settingconfig/${admin}`);
}

export function updateSettingconfig(settingconfig) {

  return axios.put(`${process.env.REACT_APP_API_URL}/api/setting/settingconfig/${settingconfig._id}`, settingconfig );
}

export function createSettingconfig(settingconfig) {


  return axios.post(`${process.env.REACT_APP_API_URL}/api/setting/settingconfig`, settingconfig);
}

///////////////////////////


