import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import {
    Input,
    ModalProgressBar,
  } from "../../../../../../../_metronic/_partials/controls";
  import { Switch } from "@material-ui/core";

// Validation schema
const NetworkEditSchema = Yup.object().shape({
    appId: Yup.string()
      .min(1, "Minimum 1 symbol")
      .required("Placement ID is required"),
    postbackSecret: Yup.string().required("Postback Secret is required"),
    provision: Yup.number()
      .min(0, "Minimum 0")
      .max(1, "maximum 1")
      .required("Provision is required"),
    bonus: Yup.number()
      .min(0, "Minimum 0")
      .max(100, "maximum 100")
      .required("Bonus is required"),
  });

function MonlixOwForm({ saveNetwork, network, actionsLoading, history }) {
    const [check, setChecked] = useState(false);
    const [check1, setChecked1] = useState(false);
    const [bonusChecked, setBonusChecked] = useState(false);
  
    const backToNetworkList = () => {
      history.push(`/dash/network`);
    };
  
    useEffect(() => {
      network.androidEnable === 0 ? setChecked(false) : setChecked(true);
      network.iosEnable === 0 ? setChecked1(false) : setChecked1(true);
      setBonusChecked(network.bonusEnable === 1)
    }, [network]);
  
    return (
      <>
        {actionsLoading && <ModalProgressBar />}
        <Card.Body>
          <Formik
            enableReinitialize={true}
            initialValues={network}
            validationSchema={NetworkEditSchema}
            onSubmit={(values) => {
              saveNetwork(values);
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <>
                <Form>
                  <Row>
                    <Col>
                      <Field
                        name="appId"
                        component={Input}
                        placeholder="appid"
                        label="appid"
                      />
                    </Col>
                  </Row>
                  &nbsp;
                  <Row>
                    <Col>
                      <Field
                        name="provision"
                        component={Input}
                        placeholder="Provision"
                        label="Provision"
                        type="number"
                        step="0.1"
                      />
                    </Col>
                    <Col>
                      <Field
                        type="text"
                        name="postbackSecret"
                        component={Input}
                        placeholder="Posback Secret"
                        label="Posback Secret"
                      />
                    </Col>
                  </Row>
                  &nbsp;
                  <Row>
                    <Col>
                      <div style={{ display: "flex" }}>
                        <label style={{ padding: "10px" }} htmlFor="switch">
                          Enable for Android:
                        </label>
                        <Switch
                          checked={check}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                            let x;
                            e.target.checked ? (x = 1) : (x = 0);
                            setFieldValue("androidEnable", x);
                          }}
                          value="check"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div style={{ display: "flex" }}>
                        <label style={{ padding: "10px" }} htmlFor="switch">
                          Enable for IOS:
                        </label>
                        <Switch
                          checked={check1}
                          onChange={(e) => {
                            setChecked1(e.target.checked);
                            let x;
                            e.target.checked ? (x = 1) : (x = 0);
                            setFieldValue("iosEnable", x);
                          }}
                          value="check1"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        name="bonus"
                        component={Input}
                        placeholder="Bonus"
                        label="Bonus Percent"
                        type="number"
                        step="1"
                      />
                    </Col>
                    <Col>
                      <div style={{ display: "flex" }}>
                        <label style={{ padding: "10px" }} htmlFor="switch">
                          Enable Bonus:
                        </label>
                        <Switch
                          checked={bonusChecked}
                          onChange={(e) => {
                            setBonusChecked(e.target.checked);
                            let x;
                            e.target.checked ? (x = 1) : (x = 0);
                            setFieldValue("bonusEnable", x);
                          }}
                          value="check"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                    </Col>
                  </Row>
                  &nbsp;
                </Form>
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </Button>
                  <Button onClick={backToNetworkList} variant="secondry">
                    Cancel
                  </Button>
                </>
              </>
            )}
          </Formik>
        </Card.Body>
        <Card.Footer>
          <h5>Postback:</h5>
          <p>
            {process.env.REACT_APP_API_POSTBACK +
              "/postback/lootably?status={status}&trans_id={transactionID}&userid={userID}&offer_id={offerID}&hash={hash}&offerName={offerName}&ip_click={ip}&amount_local={currencyReward}&amount_usd={revenue}&postbackkey={secret}"}
          </p>
        </Card.Footer>
      </>
    );
  }
  

export default MonlixOwForm;
