// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../../../_metronic/_partials/controls";
import Upload from "../../../../setting/components/Upload";
import { CompactPicker } from "react-color";
import { Switch } from "@material-ui/core";

// Validation schema

export function RewardEditForm({ saveReward, reward, actionsLoading, onHide }) {
  const RewardEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Reward Name is required"),
    order: Yup.number().required("Priority is required"),
    file: reward._id ? Yup.mixed().optional() : Yup.mixed().required(),
    icon: reward._id ? Yup.mixed().optional() : Yup.mixed().required(),
    enabled: Yup.boolean().required("This field is required"),
    type: Yup.string().required("Type is required"),
    backgroundColor: Yup.string().optional(),
    width: Yup.number().optional(),
    height: Yup.number().optional(),
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Choose Image");
  const [icon, setIcon] = useState(null);
  const [iconName, setIconName] = useState("Choose Icon");
  const [color, setColor] = useState();
  const [check, setChecked] = useState(false);

  useEffect(() => {
    setColor(reward.backgroundColor);
    setChecked(reward.enabled);
  }, [reward]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={reward}
        validationSchema={RewardEditSchema}
        onSubmit={(values) => {
          console.log(values);
          saveReward(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Reward Name"
                      label="Reward Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="order"
                      component={Input}
                      type="number"
                      placeholder="Priority"
                      label="Priority"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Upload
                      label="Select App Logo"
                      fileName={fileName}
                      file={file}
                      name="file"
                      width="150"
                      height="150"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                        setFileName(event.target.files[0].name);
                        setFile(URL.createObjectURL(event.target.files[0]));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Upload
                      label="Select App Icon"
                      fileName={iconName}
                      file={icon}
                      name="icon"
                      width="150"
                      height="150"
                      onChange={(event) => {
                        setFieldValue("icon", event.currentTarget.files[0]);
                        setIconName(event.target.files[0].name);
                        setIcon(URL.createObjectURL(event.target.files[0]));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <p>Select background Color</p>
                    <CompactPicker
                      color={color}
                      onChangeComplete={(color) => {
                        setFieldValue("backgroundColor", color.hex);
                        setColor(color.hex);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="width"
                      component={Input}
                      placeholder="Reward logo width"
                      label="Reward logo width"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="height"
                      component={Input}
                      placeholder="Reward logo height"
                      label="Reward logo height"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable reward:
                    </label>
                    <Switch
                      checked={check}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setFieldValue("enabled", e.target.checked);
                      }}
                      value="check"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Select name="type" label="Reward type:">
                      <option value="CASH">CASH</option>
                      <option value="GIFT_CARD">GIFT_CARD</option>
                      <option value="SKINS">SKINS</option>
                      <option value="CRYPTO">CRYPTO</option>
                    </Select>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
