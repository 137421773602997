import axios from "axios";

export const CUSTOMERS_URL = "api/notification";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result

export function createNotification(notification) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/notification/`, notification);
}

