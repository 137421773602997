import axios from "axios";

export const CUSTOMERS_URL = "api/nativeAds";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findNativeAds(name,admin) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/nativeAds/${name}/${admin}`);
}

export function updateNativeAds(nativeAds) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/nativeAds/${nativeAds._id}`, nativeAds );
}

export function createNativeAds(nativeAds) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/nativeAds/`, nativeAds);
}

