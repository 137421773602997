import * as requestFromServer from "./settingconfigCrud";
import {settingconfigSlice, callTypes} from "./settingconfigSlice";

const {actions} = settingconfigSlice;

export const fetchSettingconfig = (admin) => dispatch => {
  if (!admin) {
    return dispatch(actions.settingconfigFetched({ settingconfigForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findSettingconfig(admin)
    .then(response => {
      const settingconfig = response.data;
      dispatch(actions.settingconfigFetched({ settingconfigForEdit: settingconfig }));
    })
    .catch(error => {
      error.clientMessage = "Can't find settingconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.settingconfigFetched({ settingconfigForEdit: undefined }));
    });
};

export const createSettingconfig = settingconfigForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSettingconfig(settingconfigForCreation)
    .then(response => {
      const { settingconfig } = response.data;
      dispatch(actions.settingconfigCreated(settingconfig ));
    })
    .catch(error => {
      error.clientMessage = "Can't create settingconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSettingconfig = settingconfig => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSettingconfig(settingconfig)
    .then(() => {
      dispatch(actions.settingconfigUpdated(settingconfig ));
    })
    .catch(error => {
      error.clientMessage = "Can't update settingconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

///

