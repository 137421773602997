import React, { useState } from "react";
import { NetworkLoadingDialog } from "./network-loading-dialog/NetworkLoadingDialog";

import NetworkCard from "./components/NetworkCard";
import { CardDeck } from "react-bootstrap";

import adgate from "./img/adgate.png";
import hang from "./img/hang.png";
import ipdata from "./img/ipdata.png";
import ogads from "./img/ogads.png";
import applovin from "./img/applovin.png";
import adgem from "./img/adgem.png";
import cpx from "./img/cpx.jpg";
import loot from "./img/loot.jpg";
import bitlabs from "./img/bitlabs.jpg";
import pollfish from "./img/pollfish.png";
import timewall from "./img/timewall.png";
import inbrain from "./img/inbrain.png";
import mmwall from "./img/mmwall.png";
import monlix from "./img/monlix.png";
import notik from "./img/notik.jpg";
import revlum from "./img/revlum.png";
import theoremreach from "./img/theoremreach.jpg"
import mychips from "./img/mychips.png";
import appsprize from "./img/appsPrize.svg";
import tapresearch from "./img/tapresearch.png";





export function NetworkPage({ history }) {
  function openNetworkEditPage(name) {
    history.push(`/dash/network/${name}`);
  }

  const [activeTab, saveCurrentTab] = useState(0);

  const [key, setKey] = useState(activeTab ? +activeTab : 0);

  return (
    <>
      <NetworkLoadingDialog />
      <>
        <div className="card card-custom">
          {/*Header*/}
          <div className="card-header card-header-tabs-line">
            <ul
              className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
              data-remember-tab="tab_id"
              role="tablist"
            >
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 0 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(0);
                    saveCurrentTab(0);
                  }}
                >
                  API
                </span>
              </li>

              <li className="nav-item">
                <span
                  className={`nav-link ${key === 1 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(1);
                    saveCurrentTab(1);
                  }}
                >
                  OfferWall
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={`nav-link ${key === 2 ? "active" : ""}`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(2);
                    saveCurrentTab(2);
                  }}
                >
                  Native ADS
                </span>
              </li>
            </ul>
          </div>
          <div className="form">
            <div className="card-body">
              <div className="tab-content pt-3">
                <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                  <CardDeck>
                    <NetworkCard
                      network="IpData"
                      networkDescription="Lookup the location of any IP Address to localize your website content, analyze logs, enrich forms, target ads, enforce GDPR compliance, perform redirections, block countries and more."
                      img={ipdata}
                      onClick={() => openNetworkEditPage("ipdata")}
                    />
                    <NetworkCard
                      network="HangMyAds"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={hang}
                      onClick={() => openNetworkEditPage("hangmyads")}
                    />
                    <NetworkCard
                      network="OgAds"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={ogads}
                      onClick={() => openNetworkEditPage("ogads")}
                    />
                  </CardDeck>
                </div>
                <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
                  <CardDeck>
                    <NetworkCard
                      network="AdgateMedia"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={adgate}
                      onClick={() => openNetworkEditPage("adgateow")}
                    />
                    <NetworkCard
                      network="HangMyAds"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={hang}
                      onClick={() => openNetworkEditPage("hangmyadsow")}
                    />
                    <NetworkCard
                      network="AdGem"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={adgem}
                      onClick={() => openNetworkEditPage("adgemow")}
                    />
                    <NetworkCard
                      network="appsprize"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={appsprize}
                      onClick={() => openNetworkEditPage("appsprize")}
                    />
                    <NetworkCard
                      network="CPX-RESEARCH"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={cpx}
                      onClick={() => openNetworkEditPage("cpxresearch")}
                    />
                    <NetworkCard
                      network="Lootably"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={loot}
                      onClick={() => openNetworkEditPage("lootably")}
                    />
                    <NetworkCard
                      network="Monlix"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={monlix}
                      onClick={() => openNetworkEditPage("monlix")}
                    />
                    <NetworkCard
                      network="Mmwall"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={mmwall}
                      onClick={() => openNetworkEditPage("mmwall")}
                    />
                    <NetworkCard
                      network="Notik"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={notik}
                      onClick={() => openNetworkEditPage("notik")}
                    />
                    <NetworkCard
                      network="Revlum"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={revlum}
                      onClick={() => openNetworkEditPage("revlum")}
                    />
                    <NetworkCard
                      network="Theoremreach"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={theoremreach}
                      onClick={() => openNetworkEditPage("theoremreach")}
                    />
                    <NetworkCard
                      network="Mychips"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={mychips}
                      onClick={() => openNetworkEditPage("mychips")}
                    />
                    <NetworkCard
                      network="Bitlabs"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={bitlabs}
                      onClick={() => openNetworkEditPage("bitlabs")}
                    />
                    <NetworkCard
                      network="Pollfish"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={pollfish}
                      onClick={() => openNetworkEditPage("pollfish")}
                    />
                    <NetworkCard
                      network="TimeWall"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={timewall}
                      onClick={() => openNetworkEditPage("timewall")}
                    />
                    <NetworkCard
                      network="Tapresearch"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={tapresearch}
                      onClick={() => openNetworkEditPage("tapresearch")}
                    />
                    <NetworkCard
                      network="InBrain"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={inbrain}
                      onClick={() => openNetworkEditPage("inbrain")}
                    />
                  </CardDeck>
                </div>
                <div className={`tab-pane ${key === 2 ? "active" : ""}`}>
                  <CardDeck>
                    <NetworkCard
                      network="AppLovin"
                      networkDescription="This is a multiple offer ad unit. It allows a simple one-call implementation without requiring the development of a custom offer display."
                      img={applovin}
                      onClick={() => openNetworkEditPage("applovin")}
                    />
                  </CardDeck>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
