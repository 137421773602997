import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {CustomersPage} from "./modules/Dash/pages/customers/CustomersPage";
import {RedeemsPage} from "./modules/Dash/pages/redeems/RedeemsPage";
import {OffersPage} from "./modules/Dash/pages/offers/OffersPage";
import {CountriesPage} from "./modules/Dash/pages/rewards/countries/CountriesPage";


import {DashboardPage1} from "./pages/DashboardPage";
import {GiveawayPage} from "../app/modules/Dash/pages/giveaway/GiveawayPage";
import { RedeemEditDialog } from "../app/modules/Dash/pages/redeems/redeem-edit-dialog/RedeemEditDialog";
import { CountryEdit } from "../app/modules/Dash/pages/rewards/countries/country-edit/CountryEdit";
import { RewardEdit } from "../app/modules/Dash/pages/rewards/countries/reward-edit/RewardEdit";
import { NetworkPage } from "./modules/Dash/pages/network/NetworkPage";
import { NetworkEditDialog } from "./modules/Dash/pages/network/network-edit-dialog/NetworkEditDialog";
import { SettingPage } from "./modules/Dash/pages/setting/SettingPage";

import {NotificationPage} from "../app/modules/Dash/pages/notification/NotificationPage";
import RedeemPaymentPage from "./modules/Dash/pages/redeems/redeem-payment-page/RedeemPaymentPage";






export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage1}/>
                <ContentRoute path="/dash/customers" component={CustomersPage} />
                <ContentRoute path="/dash/redeems/:_id/edit" component={RedeemEditDialog} />
                <ContentRoute path="/dash/redeems/:_id/payment/:userId" component={RedeemPaymentPage} />
       

                <ContentRoute path="/dash/redeems" component={RedeemsPage} />
                <ContentRoute path="/dash/offers" component={OffersPage} />
                <ContentRoute path="/dash/rewards" component={CountriesPage} />
                <ContentRoute path="/dash/countries/:_id/add" component={CountryEdit} />
                <ContentRoute path="/dash/countries/rewards/:_id/add" component={RewardEdit} />

                <ContentRoute path="/dash/giveaway" component={GiveawayPage}/>
                
                <ContentRoute path="/dash/network/:name" component={NetworkEditDialog}/>

                <ContentRoute path="/dash/network" component={NetworkPage}/>
                <ContentRoute path="/dash/setting" component={SettingPage}/>

                <ContentRoute path="/dash/notification" component={NotificationPage}/>




                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
