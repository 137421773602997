import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GiveawayLoadingDialog } from "./giveaway-loading-dialog/GiveawayLoadingDialog";
import * as actions from "../../_redux/giveaway/giveawayActions";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { TextArea } from "../../../../../_metronic/_partials/controls/forms/TextArea";
import {WinnerForm} from "./WinnerForm";
import "./css/giveaway.css";
import { Switch } from "@material-ui/core";

const giveawaySchema = Yup.object().shape({
  giveawayTitle: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Title is required"),
  giveawayDescription: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(400, "Maximum 400 symbols")
    .required("Description is required"),
});

const initGiveway = {
  id: undefined,
  giveawayTitle: "",
  giveawayDescription: "",
  giveawayStatus: 0,
  winnerTitle: "",
  winnerDescription: "",
  winnerStatus: 0,
};

export function GiveawayPage({ history }) {
  const { actionsLoading, currentState } = useSelector(
    (state) => ({
      actionsLoading: state.giveaway.actionsLoading,
      currentState: state.giveaway,
    }),
    shallowEqual
  );

  const { user } = useSelector((state) => state.auth);
  const { data } = currentState;

  // Giveaway Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    // server call by queryParams
    dispatch(actions.fetchGiveaway(user._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);

  const saveGiveaway = (giveaway) => {
    if (!giveaway._id) {
      giveaway.adminId = user._id;
      // server request for creating customer
      dispatch(actions.createGiveaway(giveaway)).then(() => {
      });
    } else {
      // server request for updating customer
      dispatch(actions.updateGiveaway(giveaway)).then(() => {
      });
    }
  };

  const DataFormik = data || initGiveway;
  const [check, setChecked] = useState(true);
  useEffect(() => {
    DataFormik.giveawayStatus === 0 ? setChecked(false) : setChecked(true);
  }, [DataFormik]);
  return (
    <>
      <GiveawayLoadingDialog />

      <Card className="mb-10">
        <Card.Body>
          <Card>
            <Card.Header
              
              style={{
                padding: "10px 0px 0px 0px",
                backgroundColor: "#1e1e2d"
              }}
            >
              <Container>
                <Row className="align-items-center">
                  <Col>
                    <h3
                      style={{
                        color: "white",
                        padding: "8px 0px",
                      }}
                    >
                      Giveaway
                    </h3>
                  </Col>
                </Row>
              </Container>
            </Card.Header>
            <Card.Body
              style={{
                padding: "10px 10px 10px 10px",
              }}
            >
              <Formik
                enableReinitialize={true}
                initialValues={DataFormik}
                validationSchema={giveawaySchema}
                onSubmit={(values) => {
                  saveGiveaway(values);
                }}
              >
                {({ handleSubmit, setFieldValue }) => (
                  <>
                    <Form className="form form-label-right">
                      <div className="col-lg-12 giveaway-formik">
                        <Field
                          name="giveawayTitle"
                          component={Input}
                          placeholder="Giveway Title"
                          label="Giveway Title"
                        />
                      </div>
                      <div className="col-lg-12 giveaway-formik">
                        <Field
                          name="giveawayDescription"
                          row={4}
                          component={TextArea}
                          placeholder="Giveaway Description"
                          label="Giveaway Description"
                        />
                      </div>

                      <div className="col-lg-12 giveaway-formik">
                        <div style={{ display: "flex" }}>
                          <label style={{ padding: "10px" }} htmlFor="switch">
                            Enable:
                          </label>
                          <Switch
                            checked={check}
                            onChange={(e) => {
                              setChecked(e.target.checked);
                              let x;
                              e.target.checked ? (x = 1) : (x = 0);
                              setFieldValue("giveawayStatus", x);
                            }}
                            value="check"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </div>
                      </div>
                    </Form>
                    {actionsLoading && (
                      <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                    )}
                    <Button
                      style={{ float: "right" }}
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </Button>{" "}
                  </>
                )}
              </Formik>
            </Card.Body>
            <WinnerForm winner={DataFormik} saveWinner={saveGiveaway} />
          </Card>
        </Card.Body>
      </Card>
    </>
  );
}
