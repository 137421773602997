import React, { useMemo } from "react";
import { useRewardItemsUIContext } from "./RewardItemsUIContext";



export function RewardItemsFilter() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();
  const rewardItemsUIProps = useMemo(() => {
    return {
      setQueryParams: rewardItemsUIContext.setQueryParams,
      openNewRewardItemDialog: rewardItemsUIContext.openNewRewardItemDialog,
      queryParams: rewardItemsUIContext.queryParams,
    };
  }, [rewardItemsUIContext]);



  return (
    <>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-2 margin-bottom-10-mobile">
            
          </div>
          <div className="col-md-8 margin-bottom-10-mobile"></div>
          <div className="col-md-2 text-right margin-bottom-10-mobile">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => rewardItemsUIProps.openNewRewardItemDialog()}
            >
              Create new Reward Item
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
