/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";


// const QuickActionsDropdownToggle = forwardRef((props, ref) => {
//   return (
//     <a
//       ref={ref}
//       href="#"
//       onClick={e => {
//         e.preventDefault();
//         props.onClick(e);
//       }}
//       id="kt_subheader_quick_actions"
//       className="btn btn-sm btn-clean btn-icon"
//     >
//       {" "}
//       <span className="svg-icon svg-icon-success svg-icon-lg">
//         <span className="svg-icon-success svg-icon-2x">
//           <SVG  src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")} />
//         </span>
//       </span>
//     </a>
//   );
// });

export function QuickActions() {
  return (
      <>
        
      </>
  );
}
