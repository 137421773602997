import axios from "axios";

export const CUSTOMERS_URL = "api/redeems";

axios.defaults.headers.post["Content-Type"] = "application/json";

// CREATE =>  POST: add a new redeem to the server
export function createRedeem(redeem) {
  return axios.post(CUSTOMERS_URL, { redeem });
}

// READ
export function getAllRedeems() {
  return axios.get(CUSTOMERS_URL);
}

export function getRedeemById(redeemId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/redeem/${redeemId}`);
}

export function getRedeemDetailsById(redeemId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/users/details/${redeemId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRedeems(queryParams) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/redeem/?group=false`,
    JSON.stringify(queryParams)
  );
}

// UPDATE => PUT: update the redeem on the server
export function updateRedeem(redeem) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/redeem/sendReward/${redeem._id}`, {
    redeem,
  });
}

// Send Phone Verification mail
export function sendPhoneVerificationMail(
  redeemId,
  userId,
  redeemEmail,
  adminId
) {
  const data = {
    userId: userId,
    redeemEmail: redeemEmail,
    adminId: adminId,
  };
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/redeem/${redeemId}/phoneVerification`,
    data
  );
}

// Send crypto payment
export function sendCryptoPayment(redeemId) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/payments/redeems/${redeemId}`);
}

// get redeem fraud detection data
export function getRedeemFraudData(redeemId, userId) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/api/payments/${redeemId}/users/${userId}`
  );
}

// UPDATE Status
export function updateStatusForRedeems(ids, status) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/redeem/state/ids`, {
    ids,
    status,
  });
}

// DELETE => delete the redeem from the server
export function deleteRedeem(redeemId) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/redeem/${redeemId}`);
}

// DELETE Redeems by ids
export function deleteRedeems(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteRedeems`, { ids });
}
