/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, createContext, useState, useCallback } from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./RewardsUIHelper";


const RewardsUIContext = createContext();

export function useRewardsUIContext() {
  return useContext(RewardsUIContext);
}

export const RewardsUIConsumer = RewardsUIContext.Consumer;

export function RewardsUIProvider({ currentCountryId, children,history }) {
  const [countryId, setCountryId] = useState(currentCountryId);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [showEditRewardDialog, setShowEditRewardDialog] = useState(false);
  const initReward = {
    id: undefined,
    name: "",
    order: 0,
    iconUrl: "",
    countryId: countryId
  };
  useEffect(()=> {
    initReward.countryId = currentCountryId;
    initReward.carId = currentCountryId;
    setCountryId(currentCountryId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountryId]);
  const openNewRewardDialog = () => {
    setSelectedId(undefined);
    setShowEditRewardDialog(true);
  };
  const openEditRewardDialog = id => {
    setSelectedId(id);
    setShowEditRewardDialog(true);
  };
  const closeEditRewardDialog = () => {
    setSelectedId(undefined);
    setShowEditRewardDialog(false);
  };
  const [showDeleteRewardDialog, setShowDeleteRewardDialog] = useState(false);
  const openDeleteRewardDialog = id => {
    setSelectedId(id);
    setShowDeleteRewardDialog(true);
  };
  const closeDeleteRewardDialog = () => {
    setSelectedId(undefined);
    setShowDeleteRewardDialog(false);
  };

  const [showDeleteRewardsDialog, setShowDeleteRewardsDialog] = useState(false);
  const openDeleteRewardsDialog = () => {
    setShowDeleteRewardsDialog(true);
  };
  const closeDeleteRewardsDialog = () => {
    setShowDeleteRewardsDialog(false);
  };

  const [showFetchRewardsDialog, setShowFetchRewardsDialog] = useState(false);
  const openFetchRewardsDialog = () => {
    setShowFetchRewardsDialog(true);
  };
  const closeFetchRewardsDialog = () => {
    setShowFetchRewardsDialog(false);
  };

  const openAddItemstoRewardPage= (_id) => {
    history.push(`/dash/countries/rewards/${_id}/add`);
  }

  const value = {
    ids,
    setIds,
    countryId,
    setCountryId,
    queryParams,
    setQueryParams,
    initReward,
    selectedId,
    showEditRewardDialog,
    openNewRewardDialog,    
    openEditRewardDialog,
    closeEditRewardDialog,
    showDeleteRewardDialog,
    openDeleteRewardDialog,
    closeDeleteRewardDialog,
    showDeleteRewardsDialog,
    openDeleteRewardsDialog,
    closeDeleteRewardsDialog,
    openFetchRewardsDialog,
    closeFetchRewardsDialog,
    showFetchRewardsDialog,
    openAddItemstoRewardPage
  };
  
  return (
    <RewardsUIContext.Provider value={value}>
      {children}
    </RewardsUIContext.Provider>
  );
}
