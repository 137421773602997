// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";

import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  referCode: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(6, "Maximum 6 symbols")
    .required("Invite Code is required"),
  provision: Yup.number()
    .min(0, "Minimum 0")
    .max(100, "Maximum 100")
    .required("Provision is required"),
  points: Yup.number().required("Credit is required"),
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [verfied, setVerfied] = useState("false");
  useEffect(()=>{
    customer.isVerfied ? setVerfied("true") : setVerfied("false");
  },[customer]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && <ModalProgressBar />}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Shared Key */}
                  <div className="col-lg-4">
                    <Field
                      name="referCode"
                      component={Input}
                      placeholder="Invite Code"
                      label="Shared Key"
                    />
                  </div>

                  {/* network */}
                  <div className="col-lg-4">
                    <Select
                      value={verfied}
                      onChange={(e) => {
                        setVerfied(e.target.value);
                        setFieldValue("isVerfied",JSON.parse(e.target.value))
                      }}
                      label="Email Verification"
                    >
                      <option key="Verfied" value="true">
                        Verfied
                      </option>
                      <option key="Unverified" value="false">
                        Unverified
                      </option>
                    </Select>
                  </div>
                  {/* add to credit */}
                  <div className="col-lg-4">
                    <Field
                      name="points"
                      component={Input}
                      placeholder="Credit"
                      label="Credit"
                      type="number"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
