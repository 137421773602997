import React from "react";
import { Route } from "react-router-dom";
import { RedeemsLoadingDialog } from "./redeems-loading-dialog/RedeemsLoadingDialog";
import { RedeemDeleteDialog } from "./redeem-delete-dialog/RedeemDeleteDialog";
import { RedeemsFetchDialog } from "./redeems-fetch-dialog/RedeemsFetchDialog";
import { RedeemsUpdateStateDialog } from "./redeems-update-status-dialog/RedeemsUpdateStateDialog";
import { RedeemFetchDetails } from "./redeem-fetch-details/RedeemFetchDetails";
import { RedeemsUIProvider } from "./RedeemsUIContext";
import { RedeemsCard } from "./RedeemsCard";
import { RedeemPhoneDialog } from "./redeem-phone-dialog/RedeemPhoneDialog";

export function RedeemsPage({ history }) {
  const redeemsUIEvents = {
    openEditRedeemDialog: (_id) => {
      history.push(`/dash/redeems/${_id}/edit`);
    },
    openFetchRedeemsDialog: () => {
      history.push(`/dash/redeems/fetch`);
    },
    openUpdateRedeemsStatusDialog: () => {
      history.push("/dash/redeems/updateStatus");
    },
    openFetchRedeemsDetailsDialog: (_id) => {
      history.push(`/dash/redeems/${_id}/details`);
    },
    openDeleteRedeemDialog: (_id) => {
      history.push(`/dash/redeems/${_id}/cancel`);
    },
    openPhoneVerificationDialog: (_id) => {
      history.push(`/dash/redeems/${_id}/phone`);
    },
      openCryptoPaymentPage: (_id, redeemId) => {
          history.push(`/dash/redeems/${_id}/payment/${redeemId}`);
      },
    openRedeemPaymentPage: (_id) => {
      history.push(`/dash/redeems/${_id}/payment`);
    },
  };

  return (
    <RedeemsUIProvider redeemsUIEvents={redeemsUIEvents}>
      <RedeemsLoadingDialog />
      <Route path="/dash/redeems/:_id/cancel">
        {({ history, match }) => (
          <RedeemDeleteDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/redeems");
            }}
          />
        )}
      </Route>

      <Route path="/dash/redeems/:_id/phone">
        {({ history, match }) => (
          <RedeemPhoneDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/redeems");
            }}
          />
        )}
      </Route>

      <Route path="/dash/redeems/:_id/details">
        {({ history, match }) => (
          <RedeemFetchDetails
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/redeems");
            }}
          />
        )}
      </Route>
      <Route path="/dash/redeems/fetch">
        {({ history, match }) => (
          <RedeemsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/redeems");
            }}
          />
        )}
      </Route>
      <Route path="/dash/redeems/updateStatus">
        {({ history, match }) => (
          <RedeemsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/redeems");
            }}
          />
        )}
      </Route>
      <RedeemsCard />
    </RedeemsUIProvider>
  );
}
