import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/redeems/redeemsActions";
import { useRedeemsUIContext } from "../RedeemsUIContext";

export function RedeemDeleteDialog({ id, show, onHide }) {
  // Redeems UI Context
  const redeemsUIContext = useRedeemsUIContext();
  const redeemsUIProps = useMemo(() => {
    return {
      setIds: redeemsUIContext.setIds,
      queryParams: redeemsUIContext.queryParams,
    };
  }, [redeemsUIContext]);

  // Redeems Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.redeems.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteRedeem = () => {
    // server request for deleting redeem by id
    dispatch(actions.deleteRedeem(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchRedeems(redeemsUIProps.queryParams));
      // clear selections list
      redeemsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
    show={show}
    onHide={onHide}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    {isLoading && <ModalProgressBar variant="query" />}
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        Cancel Redeem
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {!isLoading && (
        <span>Would you like to cancel redeem?</span>
      )}
      {isLoading && <span>Reward is canceling...</span>}
    </Modal.Body>
    <Modal.Footer>
      <div>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          close
        </button>
        <> </>
        <button
          type="button"
          onClick={deleteRedeem}
          className="btn btn-danger btn-elevate"
        >
          Cancel Redeem
        </button>
      </div>
    </Modal.Footer>
  </Modal>
  );
}
