import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useRewardsUIContext } from "./RewardsUIContext";

const selectedRewards = (entities, ids) => {
  const _rewards = [];
  ids.forEach((id) => {
    const reward = entities.find((el) => el.id === id);
    if (reward) {
      _rewards.push(reward);
    }
  });
  return _rewards;
};

export function RewardsFetchDialog() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return {
      ids: rewardsUIContext.ids,
      queryParams: rewardsUIContext.queryParams,
      showFetchRewardsDialog: rewardsUIContext.showFetchRewardsDialog,
      closeFetchRewardsDialog: rewardsUIContext.closeFetchRewardsDialog,
    };
  }, [rewardsUIContext]);

  const { rewards } = useSelector(
    (state) => ({
      rewards: selectedRewards(state.rewards.entities, rewardsUIProps.ids),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!rewardsUIProps.ids || rewardsUIProps.ids.length === 0) {
      rewardsUIProps.closeFetchRewardsDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardsUIProps.ids]);

  return (
    <Modal
      show={rewardsUIProps.showFetchRewardsDialog}
      onHide={rewardsUIProps.closeFetchRewardsDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {rewards.map((reward) => (
              <div className="list-timeline-item mb-3" key={reward.id}>
                <span className="list-timeline-text">
                  <span
                    className="label label-lg label-light-success label-inline"
                    style={{ width: "60px" }}
                  >
                    ID: {reward.id}
                  </span>{" "}
                  <span className="ml-5">{reward.text} </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={rewardsUIProps.closeFetchRewardsDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={rewardsUIProps.closeFetchRewardsDialog}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
