import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RedeemStatusCssClasses } from "../RedeemsUIHelpers";
import * as actions from "../../../_redux/redeems/redeemsActions";
import { useRedeemsUIContext } from "../RedeemsUIContext";

const selectedRedeems = (entities, ids) => {
  const _redeems = [];
  ids.forEach((id) => {
    const redeem = entities.find((el) => el._id === id);
    if (redeem) {
      _redeems.push(redeem);
    }
  });
  return _redeems;
};

export function RedeemsUpdateStateDialog({ show, onHide }) {
  // Redeems UI Context
  const redeemsUIContext = useRedeemsUIContext();
  const redeemsUIProps = useMemo(() => {
    return {
      ids: redeemsUIContext.ids,
      setIds: redeemsUIContext.setIds,
      queryParams: redeemsUIContext.queryParams,
    };
  }, [redeemsUIContext]);

  // Redeems Redux state
  const { redeems, isLoading } = useSelector(
    (state) => ({
      redeems: selectedRedeems(
        state.redeems.entities,
        redeemsUIProps.ids
      ),
      isLoading: state.redeems.actionsLoading,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!redeemsUIProps.ids || redeemsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemsUIProps.ids]);

  const [status, setStatus] = useState(2);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for update redeems status by selected ids
    dispatch(actions.updateRedeemsStatus(redeemsUIProps.ids, status)).then(
      () => {
        // refresh list after deletion
        dispatch(actions.fetchRedeems(redeemsUIProps.queryParams)).then(
          () => {
            // clear selections list
            redeemsUIProps.setIds([]);
            // closing delete modal
            onHide();
          }
        );
      }
    );
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Status Will be updated for selected Redeems
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block">
        {/*begin::Loading*/}
        {isLoading && (
          <div className="overlay-layer">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        {/*end::Loading*/}

        <div className="timeline timeline-5 mt-3">
          {redeems.map((redeem) => (
            <div
              className="timeline-item align-items-start"
              key={`redeemsUpdate${redeem._id}`}
            >
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${
                    RedeemStatusCssClasses[redeem.status]
                  } icon-xxl`}
                />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span
                  className={`label label-lg label-light-${
                    RedeemStatusCssClasses[redeem.status]
                  } label-inline`}
                >
                  ID: {redeem._id}
                </span>
                <span className="ml-3">
                  {redeem.redeemEmail}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select
            className="form-control"
            value={status}
            onChange={(e) => setStatus(+e.target.value)}
          >
            <option value="0">Pending</option>
            <option value="1">Sent</option>
            <option value="2">Rejected</option>
            <option value="3">On hold</option>

          </select>
        </div>
        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate mr-3"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Update Status
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
