/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/rewards/rewardsActions";
import { useRewardsUIContext } from "./RewardsUIContext";

export function RewardsDeleteDialog() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return {
      ids: rewardsUIContext.ids,
      setIds: rewardsUIContext.setIds,
      countryId: rewardsUIContext.countryId,
      queryParams: rewardsUIContext.queryParams,
      showDeleteRewardsDialog: rewardsUIContext.showDeleteRewardsDialog,
      closeDeleteRewardsDialog: rewardsUIContext.closeDeleteRewardsDialog,
    };
  }, [rewardsUIContext]);

  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.rewards.actionsLoading }),
    shallowEqual
  );

  useEffect(() => {}, [isLoading, dispatch]);
  useEffect(() => {
    if (!rewardsUIProps.ids || rewardsUIProps.ids.length === 0) {
      rewardsUIProps.closeDeleteRewardsDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardsUIProps.ids]);

  const deleteRewards = () => {
    dispatch(actions.deleteRewards(rewardsUIProps.ids)).then(() => {
      dispatch(
        actions.fetchRewards(
          rewardsUIProps.queryParams,
          rewardsUIProps.countryId
        )
      ).then(() => {
        rewardsUIProps.setIds([]);
        rewardsUIProps.closeDeleteRewardsDialog();
      });
    });
  };

  return (
    <Modal
      show={rewardsUIProps.showDeleteRewardsDialog}
      onHide={rewardsUIProps.closeDeleteRewardsDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Rewards Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected rewards?</span>
        )}
        {isLoading && <span>Rewards are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={rewardsUIProps.closeDeleteRewardsDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRewards}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
